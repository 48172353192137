<template>
  <section
    class="tw-px-4 tw-w-full tw-box-border tw-max-w-[1070px] 2xl:tw-max-w-[1200px] tw-mx-auto tw-min-h-screen"
  >
    <div class="tw-mb-8">
      <p class="tw-flex tw-gap-x-2 tw-text-gray-bg2 tw-items-center">
        <BackBtn /> Go back
      </p>
    </div>
    <h1 class="tw-text-secondary tw-font-bold tw-mb-3">
      Select a deal to link to
    </h1>
    <section
      class="tw-bg-white tw-border tw-border-[#F1F3F6] tw-px-8 md:tw-px-12 tw-py-8 spv-deals__div tw-rounded"
    >
      <template v-if="dealWithoutSpv && dealWithoutSpv.length > 0">
        <div class="spv-deals-list__div">
          <DealCard
            :class="[currentDeal === deal ? 'spv-deals__card' : null]"
            @click="currentDeal = deal"
            v-for="deal in deals"
            :key="deal._id"
            :deal="deal"
            componentType="button"
          />
        </div>
        <button
          class="tw-rounded tw-mt-10 tw-h-12 tw-w-max tw-px-8 tw-font-CircularStd-medium tw-font-medium tw-text-normal tw-text-white tw-ml-auto tw-block"
          :class="[currentDeal ? 'tw-bg-primary' : 'tw-bg-gray-light']"
          :disabled="!currentDeal"
          @click="showConfirmModal = true"
        >
          Link to this deal
        </button>
      </template>
      <EmptyState v-else>
        <template v-slot:message>
          <p class="message tw-text-center tw-text-gray tw-my-3">
            {{
              deals && deals.length < 1
                ? "You have not created any deal yet."
                : "All your deals have been linked to an SPV."
            }}
          </p></template
        >
      </EmptyState>
    </section>
    <ConfirmSpvLink
      v-if="showConfirmModal"
      :deal="currentDeal || { name: 'TalentQL' }"
      @close-modal="showConfirmModal = false"
      @link-deal="linkDeal"
    />
    <SpvLinkSuccess
      v-if="showSuccessModal"
      @close-modal="redirectToSpvs"
      text="You can link another deal to this SPV whenever you want. To unlink a deal, please contact support."
    />
  </section>
</template>

<script setup>
  import BackBtn from "@/components/general/BackBtn.vue";
  import DealCard from "@/components/deals/DealCard.vue";
  import EmptyState from "@/components/notification/EmptyState.vue";
  import Pagination from "@/components/navigation/Pagination.vue";
  import ConfirmSpvLink from "@/components/spv/ConfirmSpvLink.vue";
  import SpvLinkSuccess from "@/components/spv/SpvLinkSuccess.vue";
  import { useStore } from "vuex";
  import { computed, ref } from "vue";
  import { useRoute, useRouter } from "vue-router";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const page = ref(1);
  const limit = ref(10);
  const currentDeal = ref(null);
  const showConfirmModal = ref(false);
  const showSuccessModal = ref(false);

  const deals = computed(() => store.getters["Deals/dealRequests"]);
  const dealWithoutSpv = computed(() =>
    deals.value.filter((deal) => !deal.spv)
  );

  const fetchDeals = async () => {
    const query = { page: page.value, limit: limit.value, status: "Pending" };
    await store.dispatch("Deals/getDealRequests", query);
  };
  const linkDeal = async () => {
    const id = currentDeal.value._id;
    const deal = route.params.id;
    try {
      await store.dispatch("Deals/updateDealRequest", {
        id,
        deal: { spv: deal },
      });
      showConfirmModal.value = false;
      showSuccessModal.value = true;
    } catch (error) {
      return error;
    }
  };
  const redirectToSpvs = () => {
    showSuccessModal.value = false;
    router.push({ name: "Spv" });
  };
  fetchDeals();
</script>

<style lang="scss" scoped>
  .spv-deals {
    &__div {
      box-shadow: 0px 2px 4px rgba(44, 73, 137, 0.06);
    }
    &-list {
      &__div {
        @media screen and (min-width: 640px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 0.5rem;
        }

        @media screen and (min-width: 768px) {
          column-gap: 1rem;
        }

        @media screen and (min-width: 1024px) {
          grid-template-columns: repeat(3, 1fr);
          column-gap: 14px;
        }
      }
    }
    &__card {
      border: 1px solid $brand-primary;
    }
  }
</style>
