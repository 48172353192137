<template>
  <section class="spv-form__section tw-p-6 tw-pb-8">
    <h2
      class=" tw-text-secondary tw-flex tw-items-center tw-mb-4 tw-font-CircularStd-medium tw-font-medium"
    >
      <img
        src="@/assets/img/file.svg"
        class="tw-mr-2 tw-h-8 tw-w-8 tw-rounded-full"
        alt="file icon"
      />
      SPV Details
      <button
        type="button"
        class="tw-text-xs tw-text-gray tw-ml-10 hover:tw-bg-white hover:tw-text-gray"
        @click="showModal = true"
      >
        What is an SPV?
      </button>
    </h2>
    <form
      action=""
      class="tw-mt-9 tw-max-w-[690px] tw-mx-auto"
      @submit.prevent="createSpvRequest"
    >
      <div class="tw-mb-6 tw-w-full md:tw-flex tw-gap-x-3 lg:tw-gap-x-10">
        <div class="tw-mb-8 tw-w-full md:tw-w-1/2 md:tw-max-w-[325px]">
          <label
            for="country"
            class="tw-text-secondary tw-text-base tw-font-CircularStd-medium tw-font-medium tw-mb-2"
            >Country of Incorporation</label
          >
          <Dropdown
            :required="true"
            class="tw-mt-2 tw-w-full"
            name="country"
            label="Select Country"
            :validate="true"
            :currentValue="country"
            :options="spvCountries"
            @input-changed="storeValue"
          />
          <!-- <p class="tw-text-sm tw-text-gray tw-mt-3">
            How does my location affect SPV fees?
          </p> -->
        </div>
        <div
          class="tw-w-full md:tw-w-1/2 tw-rounded tw-bg-gray-light tw-h-12 md:tw-mt-[31px] tw-py-3 tw-box-border tw-px-12"
          v-if="spv.should_manage_spv"
        >
          <p class="tw-font-bold tw-text-secondary tw-text-base">
            <span class="tw-text-base tw-text-black-light tw-font-normal"
              >SPV Fees:</span
            >
            USD 30,000
          </p>
        </div>
      </div>
      <div class="tw-w-full tw-mt-10">
        <label
          for="country"
          class="tw-text-secondary tw-text-base tw-font-CircularStd-medium tw-font-medium tw-mb-2"
          >Proposed SPV Names</label
        >
        <p class="tw-font-light tw-text-sm tw-text-gray tw-mb-8">
          Based on availability, any of these names will be used.
        </p>
        <div class="tw-mt-8 md:tw-mt-12 md:tw-flex tw-gap-x-3 lg:tw-gap-x-10">
          <InputField
            label="Name of SPV 1"
            class="tw-mb-4 tw-w-full md:tw-w-1/2"
            :currentValue="spv.ProposedName1"
            :validate="false"
            name="ProposedName1"
            @input-changed="storeValue"
          />
          <InputField
            label="Name of SPV 2"
            class="tw-mb-4 tw-w-full md:tw-w-1/2"
            :currentValue="spv.ProposedName2"
            :validate="false"
            name="ProposedName2"
            @input-changed="storeValue"
          />
        </div>
        <div class="tw-mt-6 md:tw-max-w-[325px]">
          <InputField
            label="Name of SPV 3"
            class="tw-mb-4 tw-w-full"
            :currentValue="spv.ProposedName3"
            :validate="false"
            name="ProposedName3"
            @input-changed="storeValue"
          />
        </div>
        <div class="tw-rounded-[5px] tw-p-7 tw-bg-gray-light tw-mt-10">
          <div class="tw-flex tw-justify-between tw-flex-wrap">
            <p
              class="tw-text-secondary tw-text-medium tw-font-CircularStd-medium tw-mb-3 md:tw-mb-0"
            >
              Allow GetEquity manage your SPV
            </p>
            <ToggleButton
              :value="spv.should_manage_spv"
              @value-selected="setManageStatus"
            />
          </div>

          <p
            class="tw-text-xs tw-text-black-light tw-mt-2 tw-leading-[18px] tw-max-w-[532px]"
          >
            When turned on, GetEquity will be able to manage your SPV for you.
            <br />
            This means GetEquity will act as a Corporate Shareholder who holds
            your investors in trust and also help track the SPV shareholders and
            the number of shares owned at a particular time.
          </p>
        </div>
        <div class="tw-mr-auto tw-max-w-[192px] tw-mt-10">
          <BtnComponent
            title="Continue"
            :loading="loading"
            :disabled="loading"
          />
        </div>
      </div>
    </form>
    <SpvModal
      v-if="showModal"
      @show-spv-options="showModal = false"
      @close-spv-modal="showModal = false"
    />
  </section>
</template>

<script setup>
  import Dropdown from "@/components/general/Dropdown";
  import { computed, ref } from "vue";
  import SpvModal from "@/components/spv/SpvModal.vue";
  import InputField from "../general/InputField.vue";
  import BtnComponent from "../general/BtnComponent.vue";
  import ToggleButton from "../general/ToggleButton.vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import countryCode from "@/utils/json/countryCode.json";
  import { getItem, removeItem } from "@/utils/storage";

  const store = useStore();
  const router = useRouter();

  const spv = ref({
    country: null,
    ProposedName1: "",
    ProposedName2: "",
    ProposedName3: "",
    should_manage_spv: false,
    fee: 100,
  });

  const country = ref("");
  const showModal = ref(false);

  const spvCountries = computed(() => store.getters["Spv/spvCountries"]);
  const loading = computed(() => store.getters["loading"]);

  const storeValue = (object) => {
    if (object.value && object.name === "country") {
      country.value = object.value;
      spv.value[object.name] = object.value;
    } else {
      spv.value[object.name] = object.value;
    }
  };
  const setManageStatus = (value) => {
    spv.value.should_manage_spv = value;
  };
  const createSpvRequest = async () => {
    const payload = spv.value;
    try {
      const response = await store.dispatch("Spv/requestSpv", payload);
      const id = response.data.data._id;
      const dealId = getItem("dealId");
      if (dealId) {
        const payload = {
          id: dealId,
          deal: {
            spv: id,
          },
        };
        await store.dispatch("Deals/updateDealRequest", payload);
        removeItem("dealId");
      }
      router.push({ name: "SpvBusinessInfo", params: { id } });
    } catch (error) {
      return error;
    }
  };
</script>

<style lang="scss" scoped>
  .spv {
    &-form {
      &__section {
        background: #ffffff;
        border: 1px solid #f1f3f6;
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 100px;
      }
    }
  }
</style>
