<template>
  <Accordion
    :info="info"
    :verified="false"
    :icon="icon"
    @is-active="setActiveTab"
  >
    <form @submit.prevent="updateSpvRequest">
      <div class="tw-mb-6">
        <label
          for="shareCapital"
          class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67] tw-w-full"
          >Share Capital Value</label
        >
        <div class="md:tw-flex tw-items-center tw-gap-4 tw-relative">
          <InputField
            class="tw-mt-2 tw-w-full md:tw-w-1/2 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            :validateAsNumber="true"
            label="Company share value"
            name="shareCapital"
            :currentValue="shareCapital"
            :validate="validate"
            @input-changed="storeValue"
          />
          <p
            class="tw-underline tw-text-xs tw-text-gray tw-flex tw-gap-x-2 tw-items-center tw-mt-6 md:tw-mt-0"
          >
            What is share capital value?
            <Tooltip
              text="Share capital is the amount of money the company raises from the issue of common shares of the company from public and private sources."
            />
          </p>
        </div>
      </div>
      <div class="tw-mt-12">
        <h3
          class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
        >
          Shareholder Type
        </h3>
        <Dropdown
          class="tw-w-full tw-mt-2 md:tw-w-1/2 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          name="shareholderType"
          label="Shareholder Type"
          :required="true"
          :validate="validate"
          :currentValue="companyInfo.shareholderType"
          :options="shareholderTypes"
          @input-changed="storeValue"
        />
      </div>
      <div class="tw-h-12 tw-w-[130px] tw-mt-16">
        <CustomButton title="Continue" :loading="loading" :disabled="loading" />
      </div>
    </form>
  </Accordion>
</template>

<script setup>
  import { ref, inject, computed, watch } from "vue";
  import { useStore } from "vuex";
  import { setItem, getItem } from "@/utils/storage";
  import Accordion from "@/components/setup/Accordion";
  import InputField from "@/components/general/InputField";
  import { convertToLocale, strip } from "@/utils/helpers";
  import Dropdown from "@/components/general/Dropdown";
  import CustomButton from "@/components/general/BtnComponent";

  const emit = defineEmits(["is-active"]);

  const store = useStore();

  const icon = ref(
    "https://res.cloudinary.com/djalafcj9/image/upload/v1673347571/getequityV2/suitcase_icon_dzlqts.png"
  );
  const info = ref({
    title: "Company Information",
    description: null,
  });
  const validate = ref(false);
  const activeTab = inject("activeTab");
  const companyInfo = ref({
    shareCapital: null,
    shareholderType: null,
    provideBankAccount: true,
  });

  const shareCapital = ref("");
  const shareholderTypes = ref(["Mixed", "Locals", "Foreign"]);

  const shareCapitalFormatted = computed(() =>
    convertToLocale(Number(strip(shareCapital.value)))
  );
  const loading = computed(() => store.getters["loading"]);
  convertToLocale;
  strip;
  const spvInfo = computed(() => store.getters["Spv/spvInfo"]);

  const storeValue = ({ name, value }) => {
    if (name === "shareCapital") {
      shareCapital.value = value;
    } else {
      companyInfo.value[name] = value;
    }
  };
  const setActiveTab = (title) => {
    emit("is-active", title);
  };
  const fetchFormData = () => {
    const data = getItem(info.value.title);
    if (data) {
      companyInfo.value = data;
      shareCapital.value = data.shareCapital;
    }
  };
  // const updateSpvRequest = async () => {
  const updateSpvRequest = () => {
    // const spv = companyInfo.value;
    // try {
    // const id = route.params.id;
    // await store.dispatch("Spv/updateSpvRequest", { id, spv });
    emit("is-active", "Corporate Shareholder");
    // } catch (error) {
    //   return error;
    // }
  };
  watch(shareCapitalFormatted, (value) => {
    companyInfo.value.shareCapital = strip(value);
    shareCapital.value = value;
  });
  watch(
    companyInfo,
    (value) => {
      setItem(info.value.title, value);
    },
    { deep: true }
  );
  watch(
    spvInfo,
    (value) => {
      fetchFormData();
    },
    { deep: true }
  );
  fetchFormData();
</script>

<style lang="scss" scoped></style>
