<template>
  <section
    class="tw-mt-8 tw-px-8 deals md:tw-px-16 tw-max-w-[818px] tw-mx-auto tw-pb-10"
  >
    <h1 class="tw-font-bold tw-text-lg tw-text-secondary">
      Business Information
    </h1>
    <p class="tw-text-sm tw-text-black-light tw-mt-2">
      Fill in the following details of the business you’re creating an SPV for
    </p>
    <div class="tw-mt-10">
      <BusinessInformation />
    </div>
  </section>
</template>

<script setup>
  import BusinessInformation from "@/components/spv/BusinessInformation.vue";
  import { useRoute } from "vue-router";
  import { useStore } from "vuex";

  const store = useStore();
  const route = useRoute();

  const getSpvRequest = async () => {
    const id = route.params.id;
    await store.dispatch("Spv/fetchSpvRequest", id);
  };
  if (route.params.id) {
    getSpvRequest();
  }
</script>

<style lang="scss" scoped></style>
