<template>
  <Modal :show="true" @close-modal="$emit('close-modal')">
    <section class="tw-w-[320px] md:tw-w-[490px]">
      <div class="tw-max-w-[362px] tw-mx-auto">
        <template v-if="insufficientFunds">
          <img
            src="https://res.cloudinary.com/djalafcj9/image/upload/v1672925287/getequityV2/error_info_dz5hnk.png"
            class="tw-w-20 tw-h-20 tw-block tw-mx-auto"
          />
          <p
            class="tw-text-secondary tw-my-6 tw-font-bold tw-text-xl tw-text-center"
          >
            Insufficient Funds
          </p>
          <p
            class="tw-text-black-light tw-text-center tw-max-w-xs tw-mb-8 tw-mx-auto"
          >
            You do not have sufficient funds to facilitate this transaction.
            Kindly fund your wallet and continue.
          </p>
          <div class="tw-max-w-sm tw-mx-auto">
            <CustomButton
              title="Fund Wallet"
              @click="$emit('show-fund-modal')"
            />
          </div>
        </template>
        <template v-else>
          <div
            class="tw-rounded tw-bg-gray-lightest tw-w-full tw-max-w-sm tw-py-6 tw-px-9 tw-mt-10 tw-mb-8 tw-mx-auto"
          >
            <h1 class="tw-text-center tw-text-black-light tw-text-base">
              Amount
            </h1>
            <p
              class="tw-text-secondary tw-font-bold tw-text-3xl tw-text-center"
            >
              {{ formatAmountToDollar(amount, 2, "USD") }}
            </p>
          </div>
          <p
            class="tw-text-black-light tw-text-center tw-max-w-xs tw-mb-8 tw-mx-auto"
          >
            Payment will be charged from your wallet
          </p>
          <div class="tw-max-w-sm tw-mx-auto">
            <CustomButton
              @click="checkWalletBalance"
              title="Make payment"
              :loading="loading"
            />
          </div>
        </template>
      </div>
    </section>
  </Modal>
</template>

<script setup>
  import Modal from "../general/Modal.vue";
  import CustomButton from "../general/BtnComponent.vue";
  import { formatAmountToDollar } from "@/utils/helpers";
  import { computed, ref } from "vue";
  import { useStore } from "vuex";
  import { useRoute } from "vue-router";
  const emit = defineEmits([
    "close-modal",
    "show-fund-modal",
    "show-success-modal",
  ]);

  const store = useStore();
  const route = useRoute();

  const amount = ref(100);

  const insufficientFunds = ref(false);

  const walletBalance = computed(
    () => store.state.organisationModule.tradeBalance
  );

  const loading = computed(() => store.getters["loading"]);

  const getWalletBalance = async () => {
    await store.dispatch("organisationModule/getWalletBalance");
  };

  const checkWalletBalance = () => {
    if (walletBalance.value <= amount.value) {
      insufficientFunds.value = true;
    } else {
      payForSpv();
    }
  };

  const payForSpv = async () => {
    const id = route.params.id;
    await store.dispatch("Spv/payForSpv", id);
    emit("show-success-modal");
  };

  getWalletBalance();
</script>

<style lang="scss" scoped></style>
