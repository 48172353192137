<template>
  <section>
    <CompanyInfo @is-active="setActiveTab" />
    <Shareholders @is-active="setActiveTab" v-if="showShareholderForm" />
    <DirectorsInfo
      @is-active="setActiveTab"
      v-if="showDirectorForm"
      @submit="submitForm = true"
    />
    <div
      class="tw-mt-16 tw-w-32 tw-ml-auto"
      v-if="showShareholderForm && showDirectorForm && submitForm"
    >
      <CustomButton
        @click="updateSpvRequest"
        title="Continue"
        :loading="loading"
        :disabled="loading"
      />
    </div>
  </section>
</template>

<script setup>
  import CompanyInfo from "./forms/CompanyInfo.vue";
  import Shareholders from "./forms/Shareholders.vue";
  import DirectorsInfo from "./forms/DirectorsInfo.vue";
  import CustomButton from "../general/BtnComponent.vue";
  import { ref, provide, computed, watch } from "vue";
  import { useStore } from "vuex";
  import { useRoute, useRouter } from "vue-router";
  import { getItem } from "@/utils/storage";

  provide(
    "activeTab",
    computed(() => activeTab.value)
  );

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const currentSection = ref("");
  const activeTab = ref("Company Information");
  const showShareholderForm = ref(false);
  const showDirectorForm = ref(false);
  const submitForm = ref(false);
  const noComplianceList = ["Zimbabwe", "Zambia", "Uganda"];

  const spvInfo = computed(() => store.getters["Spv/spvInfo"]);
  const spvCountry = computed(() => spvInfo.value.country);
  const loading = computed(() => store.getters["loading"]);

  const handleClick = (obj) => {
    if (obj.val) {
      currentSection.value = obj.component;
    } else {
      currentSection.value = "";
    }
  };
  const setActiveTab = (tab) => {
    activeTab.value = tab;
  };

  const fetchSpvRequest = async () => {
    const id = route.params.id;
    await store.dispatch("Spv/fetchSpvRequest", id);
  };

  const updateSpvRequest = async () => {
    const companyInfo = getItem("Company Information");
    const shareholder = getItem("Shareholder");
    const director = getItem("Director");
    const spv = {
      ...companyInfo,
      shareholders: shareholder,
      directors: director,
    };
    const id = route.params.id;
    try {
      await store.dispatch("Spv/updateSpvRequest", { id, spv });
      if (noComplianceList.includes(spvCountry.value)) {
        router.push({
          name: "SpvCompliance",
          params: { id },
          query: { showPayment: true },
        });
      } else {
        router.push({ name: "SpvCompliance", params: { id } });
      }
    } catch (error) {
      console.log({ error });
      return error;
    }
  };

  watch(activeTab, (value) => {
    if (value === "Corporate Shareholder") {
      showShareholderForm.value = true;
    } else if (value === "Director") {
      showDirectorForm.value = true;
    }
  });
  watch(submitForm, (value) => {
    if (value) {
      activeTab.value = "";
    }
  });
  fetchSpvRequest();
</script>

<style lang="scss" scoped></style>
