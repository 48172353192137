<template>
  <section
    class="tw-mt-8 tw-px-8 deals md:tw-px-16 tw-max-w-[818px] tw-mx-auto tw-pb-10"
  >
    <h1 class="tw-font-bold tw-text-lg tw-text-secondary">
      Compliance
    </h1>
    <p class="tw-text-sm tw-text-black-light tw-mt-2">
      Fill in the following details of the business you’re creating an SPV for
    </p>
    <div class="tw-mt-10">
      <Compliance @submit="submitCompliance" />
    </div>
    <SpvPaymentOptions
      v-if="showPaymentOptions"
      @show-fund-modal="openFundModal"
      @show-success-modal="openSuccessModal"
      @close-modal="showPaymentOptions = false"
    />
    <FundModal v-if="showFundModal" @close-modal="checkFlow" />
    <SuccessModal
      v-if="showSuccessModal"
      @close-modal="$router.push({ name: 'Spv' })"
    />
  </section>
</template>

<script setup>
  import Compliance from "@/components/spv/forms/Compliance.vue";
  import FundModal from "@/components/wallet/fund/FundModal.vue";
  import SuccessModal from "@/components/spv/SpvPaymentSuccess.vue";
  import { onMounted, provide, ref } from "vue";
  import { useStore } from "vuex";
  import SpvPaymentOptions from "@/components/spv/SpvPaymentOptions.vue";
  import { useFundNotification } from "@/composables/notifyUserFunding";
  import { useRoute } from "vue-router";

  const store = useStore();
  const route = useRoute();

  const { checkFundingStatus, isSuccessful } = useFundNotification();

  const showPaymentOptions = ref(false);
  const showFundModal = ref(false);
  const isFromPaymentFlow = ref(false);
  const showSuccessModal = ref(false);

  provide("activeTab", "Compliance");

  const submitCompliance = async (object) => {
    try {
      await store.dispatch("Spv/updateSpvCompliance", object);
      showPaymentOptions.value = true;
    } catch (error) {
      console.log({ error });
    }
  };
  const openFundModal = () => {
    isFromPaymentFlow.value = true;
    showFundModal.value = true;
    showPaymentOptions.value = false;
  };
  const openSuccessModal = () => {
    showFundModal.value = false;
    showSuccessModal.value = true;
    showPaymentOptions.value = false;
  };
  const checkFlow = () => {
    if (isFromPaymentFlow.value) {
      showPaymentOptions.value = true;
      isFromPaymentFlow.value = false;
      showFundModal.value = false;
    } else {
      showFundModal.value = false;
    }
  };
  const payForSpv = async () => {
    const id = route.params.id;
    await store.dispatch("Spv/payForSpv", id);
    showSuccessModal.value = true;
  };
  onMounted(async () => {
    await checkFundingStatus();
    if (isSuccessful.value) {
      payForSpv();
    }
  });
</script>

<style lang="scss" scoped></style>
