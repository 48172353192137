<template>
  <section
    class="tw-bg-white tw-rounded tw-border tw-border-[#F1F3F6] tw-p-8 tw-box-border tw-max-w-[818px] tw-mx-auto"
  >
    <div
      class="tw-w-16 tw-h-16 tw-mx-auto tw-mb-4"
      v-if="spvType === 'requests' || spvType === 'spvs'"
    >
      <img src="@/assets/img/spv.svg" class="tw-mx-auto tw-w-full tw-h-full" />
    </div>
    <h1
      class="tw-text-secondary tw-font-bold tw-text-xl tw-mb-4 tw-text-center"
    >
      {{ spvHeading }}
    </h1>
    <p
      class="tw-text-base tw-text-center tw-text-black-light tw-max-w-[358px] tw-mx-auto tw-mb-6"
    >
      {{ spvText }}
    </p>
    <div class="tw-max-w-[251px] tw-mx-auto tw-w-full">
      <BtnComponent title="Create SPV" @click="showOptions = true" />
    </div>
    <button
      v-if="spvType === 'default'"
      class="tw-text-primary tw-block tw-underline tw-mx-auto tw-mt-6 tw-text-sm tw-font-CircularStd-medium tw-font-medium hover:tw-bg-white hover:tw-text-primary tw-h-10"
      @click="showSpvModal = true"
    >
      What is an SPV?
    </button>
    <SpvModal
      v-if="showSpvModal"
      @close-spv-modal="showSpvModal = false"
      @show-spv-options="switchModal('spvOptions')"
    />
    <SpvOptionsModal
      v-if="showOptions"
      @close-spv-options="showOptions = false"
    />
  </section>
</template>

<script setup>
  import BtnComponent from "@/components/general/BtnComponent.vue";
  import { ref, computed, watch } from "vue";
  import SpvModal from "@/components/spv/SpvModal.vue";
  import SpvOptionsModal from "@/components/spv/SpvOptionsModal.vue";
  const props = defineProps({
    spvType: {
      type: String,
      required: false,
      default: () => "default",
    },
    emptyType: {
      type: String, // search, filter, default
      required: false,
      default: () => "default",
    },
  });

  const spvType = computed(() => props.spvType);
  const emptyType = computed(() => props.emptyType);
  const showSpvModal = ref(false);
  const showOptions = ref(false);

  const spvHeading = computed(() => {
    let heading = null;
    switch (spvType.value) {
      case "requests":
        heading = getRequestCopy().heading;
        break;
      case "spvs":
        heading = getDocsCopy().heading;
        break;

      default:
        heading = "You do not have an SPV -- yet";
        break;
    }
    return heading;
  });
  const spvText = computed(() => {
    let text = null;
    switch (spvType.value) {
      case "requests":
        text = getRequestCopy().text;
        break;
      case "spvs":
        text = getDocsCopy().text;
        break;

      default:
        text =
          "Create an SPV that can be linked to any fund raising deal you have created.";
        break;
    }
    return text;
  });

  const getRequestCopy = () => {
    let heading = null;
    let text = null;
    switch (emptyType.value) {
      case "search":
        heading = "Not found";
        text = "You do not have an SPV with this name";
        break;
      case "filter":
        heading = "Not found";
        text = "You do not have an SPV with this status";
        break;

      default:
        heading = "You have not made any SPV requests yet";
        text =
          "Create an SPV that can be linked to any fund raising deal you have created.";
        break;
    }
    return { heading, text };
  };
  const getDocsCopy = () => {
    let heading = null;
    let text = null;
    switch (emptyType) {
      case "search":
        heading = "Not found";
        text = "You do not have an SPV Document with this name";
        break;

      default:
        heading = "You do not have an SPV -- yet";
        text =
          "Create an SPV that can be linked to any fund raising deal you have created.";
        break;
    }
    return { heading, text };
  };

  const switchModal = (modal) => {
    switch (modal) {
      case "spvOptions":
        showOptions.value = true;
        showSpvModal.value = false;
        break;
      // case value:

      //   break;
    }
  };
  watch(
    () => props.emptyType,
    () => {
      if (spvType === "requests") {
        getRequestCopy();
      } else {
        getDocsCopy();
      }
    }
  );
</script>

<style lang="scss" scoped></style>
