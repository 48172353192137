<template>
  <Accordion
    :verified="false"
    :icon="icon"
    :info="info"
    @is-active="setActiveTab"
  >
    <form @submit.prevent="checkFormValidation">
      <div class="tw-mb-10">
        <div class="tw-mt-10">
          <h3
            class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
          >
            Company Detail
          </h3>
          <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2">
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                :currentValue="shareholder.companyName"
                label="Enter company name"
                name="companyName"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <div
              class="tw-w-full md:tw-w-1/2 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                :currentValue="shareholder.companyRegistrationNumber"
                :validateAsNumber="true"
                label="Company Reg Number"
                name="companyRegistrationNumber"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
          </div>
        </div>
        <div class="tw-mt-6">
          <h3
            class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
          >
            Business Information
          </h3>
          <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2">
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                :currentValue="shareholder.companyAddress"
                label="Company Address"
                name="companyAddress"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <div
              class="tw-w-full md:tw-w-1/2 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                :currentValue="numberOfShares"
                :validateAsNumber="true"
                label="Number of shares"
                name="numberOfShares"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
          </div>
        </div>
        <div class="tw-mt-6 tw-border-b tw-border-black-light2">
          <h3
            class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
          >
            Signatory Information
          </h3>
          <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2">
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                label="Signatory Name"
                :currentValue="shareholder.signatoryName"
                name="signatoryName"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <Dropdown
                class=" tw-mt-2"
                label="Gender"
                :options="gender"
                :currentValue="shareholder.signatoryGender"
                name="signatoryGender"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
          </div>
          <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2">
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <Datepicker
                v-model="shareholder.signatoryDateOfBirth"
                placeholder="Date of birth"
                :textInput="true"
                :autoApply="true"
                dateOfIncorporation
                :enableTimePicker="false"
                class="tw-mt-2"
                :class="isError"
              />
              <p
                class="tw-text-red-error tw-w-full"
                v-if="!shareholder.signatoryDateOfBirth && isValidated"
              >
                Value is Required
              </p>
            </div>
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                type="email"
                class=" tw-mt-2"
                label="Email"
                :currentValue="shareholder.signatoryEmail"
                name="signatoryEmail"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
          </div>
          <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2">
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <Dropdown
                class=" tw-mt-2"
                label="Nationality"
                :options="nationality"
                :currentValue="shareholder.signatoryNationality"
                name="signatoryNationality"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <PhoneInput
                class=" tw-mt-2"
                label="Phone Number"
                :currentValue="shareholder.signatoryPhoneNumber"
                name="signatoryPhoneNumber"
                :validate="validate"
                @phone-input="storeValue"
                @is-valid="handleValidation"
              />
            </div>
          </div>
          <div
            class="tw-mb-6 md:tw-flex tw-items-center tw-gap-4 tw-mt-2 tw-flex-wrap"
          >
            <h3
              class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67] tw-w-full"
            >
              Passport Photo Document
            </h3>
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                label="Passport photo URL"
                name="signatoryPassportUrl"
                :currentValue="shareholder.signatoryPassportUrl"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <p
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px] tw-h-8"
            >
              Enter link or upload document.
            </p>
            <DragNDrop
              accept="image/png,image/jpeg,image/jpg,.pdf"
              :currentValue="shareholder.signatoryPassportUrl"
              :validated="validate"
              :startUpload="uploadFile"
              @re-upload="uploadFile = false"
              @file-added="setDoc($event, 'signatoryPassportUrl')"
              @upload-complete="setUpdatedUrl($event, 'signatoryPassportUrl')"
              @upload-error="idUploadError"
            />
          </div>
          <div
            class="tw-mb-12 md:tw-flex tw-items-center tw-flex-wrap tw-gap-4 tw-mt-2"
          >
            <h3
              class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67] tw-w-full"
            >
              Incorporation Document
            </h3>
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                label="Incorporation Doc URL"
                name="incorporationDocumentUrl"
                :currentValue="shareholder.incorporationDocumentUrl"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <p
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px] tw-h-8"
            >
              Enter link or upload document.
            </p>
            <DragNDrop
              accept="image/png,image/jpeg,image/jpg,.pdf"
              :currentValue="shareholder.incorporationDocumentUrl"
              :validated="validate"
              :startUpload="uploadFile"
              @re-upload="uploadFile = false"
              @file-added="setDoc($event, 'incorporationDocumentUrl')"
              @upload-complete="
                setUpdatedUrl($event, 'incorporationDocumentUrl')
              "
              @upload-error="idUploadError"
            />
          </div>
          <div
            class="tw-mb-12 md:tw-flex tw-items-center tw-flex-wrap tw-gap-4 tw-mt-2"
          >
            <h3
              class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67] tw-w-full"
            >
              Identification Document
            </h3>
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                label="Identification Doc URL"
                name="identificationDocumentUrl"
                :currentValue="shareholder.identificationDocumentUrl"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <p
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px] tw-h-8"
            >
              Enter link or upload document.
            </p>
            <DragNDrop
              accept="image/png,image/jpeg,image/jpg,.pdf"
              :currentValue="shareholder.identificationDocumentUrl"
              :validated="validate"
              :startUpload="uploadFile"
              @re-upload="uploadFile = false"
              @file-added="setDoc($event, 'identificationDocumentUrl')"
              @upload-complete="
                setUpdatedUrl($event, 'identificationDocumentUrl')
              "
              @upload-error="idUploadError"
            />
          </div>
        </div>
        <div class="tw-mt-6">
          <h3
            class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
          >
            Certificate of Incorporation
          </h3>
          <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-items-center">
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                label="Enter URL"
                :currentValue="shareholder.certificationOfIncorporationUrl"
                name="certificationOfIncorporationUrl"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <p
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px] tw-h-8"
            >
              Enter link or upload document.
            </p>
          </div>
          <DragNDrop
            accept="image/png,image/jpeg,image/jpg,.pdf"
            :currentValue="shareholder.certificationOfIncorporationUrl"
            :validated="validate"
            :startUpload="uploadFile"
            @re-upload="uploadFile = false"
            @file-added="setDoc($event, 'certificationOfIncorporationUrl')"
            @upload-complete="
              setUpdatedUrl($event, 'certificationOfIncorporationUrl')
            "
            @upload-error="idUploadError"
          />
        </div>
      </div>
      <div class="tw-h-12 tw-w-[130px]">
        <CustomButton
          title="Continue"
          :loading="loading || uploadLoading"
          :disabled="loading || uploadLoading"
        />
      </div>
    </form>
  </Accordion>
</template>
<script setup>
  import Accordion from "@/components/setup/Accordion";
  import InputField from "@/components/general/InputField";
  import CustomButton from "@/components/general/BtnComponent";
  import Dropdown from "@/components/general/Dropdown.vue";
  import DragNDrop from "@/components/general/DragNDrop";
  import TextField from "@/components/general/TextField.vue";
  import PhoneInput from "@/components/general/PhoneInput.vue";
  import nationality from "@/utils/json/nationality.json";
  import { useStore } from "vuex";
  import { useValidation } from "@/composables/formValidation";

  import useValidate from "@vuelidate/core";
  import { inject, ref, computed, watch } from "vue";

  import { convertToLocale, strip } from "@/utils/helpers";
  import { setItem, getItem } from "@/utils/storage";

  const emit = defineEmits(["is-active"]);
  const store = useStore();

  const {
    fields,
    handleValidation,
    checkFormValidation,
    validate,
    canSubmit,
  } = useValidation();

  nationality;
  const activeTab = inject("activeTab");
  const info = ref({
    title: "Shareholder",
  });
  const icon = ref(
    "https://res.cloudinary.com/djalafcj9/image/upload/v1668692227/getequityV2/fou-info_wiqluf.svg"
  );
  const isValidated = ref(false);
  const uploadFile = ref(false);
  const shareholder = ref({
    companyName: null,
    companyAddress: null,
    companyRegistrationNumber: null,
    numberOfShares: null,
    certificationOfIncorporationUrl: "",
    signatoryName: null,
    signatoryGender: null,
    signatoryDateOfBirth: null,
    signatoryEmail: null,
    signatoryNationality: null,
    signatoryPhoneNumber: null,
    signatoryPassportUrl: "",
    incorporationDocumentUrl: "",
    identificationDocumentUrl: "",
  });
  const numberOfShares = ref("");
  const gender = ref(["Male", "Female"]);
  const uploaded = ref([]);
  const filesToUpload = ref(4); // number of file uploads on the form
  const fullNumber = ref("");
  const uploadLoading = ref(false);

  const loading = computed(() => store.getters["loading"]);

  const numberOfSharesFormatted = computed(() =>
    convertToLocale(Number(strip(numberOfShares.value)))
  );

  strip;
  const storeValue = ({ name, value }) => {
    if (name === "numberOfShares") {
      numberOfShares.value = value;
    } else if (name === "signatoryPhoneNumber") {
      fullNumber.value = value;
      let number = `${value.code}${value.number}`;
      number = String(number)
        .split(" ")
        .join("");
      shareholder.value.signatoryPhoneNumber = number;
    } else {
      shareholder.value[name] = value;
    }
  };
  const setActiveTab = (title) => {
    emit("is-active", title);
  };
  const setDoc = (url, name) => {
    shareholder.value[name] = url;
  };
  const isError = computed(() => {
    if (!shareholder.value.signatoryDateOfBirth && validate.value) {
      return "date__input--error date__input";
    }
    return "date__input";
  });
  const fetchFormData = () => {
    const data = getItem(info.value.title);
    if (data) {
      shareholder.value = data;
      numberOfShares.value = data.numberOfShares;
    }
  };
  const setUpdatedUrl = (url, name) => {
    shareholder.value[name] = url;
    // push a boolean/item to this array after Cloudinary upload is complete
    uploaded.value.push(true);
    // compare array length with the number of file uploads on the form
    if (uploaded.value.length === filesToUpload.value) {
      uploadLoading.value = false;
      uploadFile.value = false;
      emit("is-active", "Director");
    }
  };
  const idUploadError = () => {
    uploadFile.value = false;
    uploadLoading.value = false;
    store.dispatch("setLoading", false);
  };
  watch(numberOfSharesFormatted, (value) => {
    shareholder.value.numberOfShares = strip(value);
    numberOfShares.value = value;
  });
  watch(
    shareholder,
    (value) => {
      setItem(info.value.title, value);
    },
    { deep: true }
  );
  watch(validate, (value) => {
    isValidated.value = true;
    if (value) {
      if (shareholder.value.signatoryDateOfBirth) {
        handleValidation(true);
      } else {
        handleValidation(false);
      }
    }
  });
  watch(canSubmit, (value) => {
    if (value) {
      uploaded.value = [];
      uploadFile.value = true;
      uploadLoading.value = true;
    }
  });
  fields.value = Object.keys(shareholder.value).length;
  fetchFormData();
</script>

<style lang="scss">
  .date {
    &__input {
      height: 48px;
      transition: all 0.3s ease;
      border: 1px solid #e1e6f0 !important;
      flex-grow: 1;
      &--error {
        border: 1px solid #db4343 !important;
      }
    }
  }
  .dp__main {
    @apply md:tw-w-full;
  }
  .dp__input {
    border: 0;
    height: 46px;
  }
</style>
