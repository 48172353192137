<template>
  <div>
    <Datepicker
      v-model="date"
      :placeholder="label"
      :textInput="true"
      :autoApply="true"
      dateOfIncorporation
      :enableTimePicker="false"
      class="tw-mt-2"
      :class="isError"
    />

    <p class="tw-text-red-error tw-font-normal" v-if="v$.$error">
      {{ v$.$errors[0].$message }}
    </p>
  </div>
</template>

<script setup>
  import { computed, ref, watch } from "vue";
  import { useVuelidate } from "@vuelidate/core";
  import { required } from "@vuelidate/validators";

  const emit = defineEmits(["date-input", "is-valid"]);
  const props = defineProps({
    name: {
      type: String,
      required: true,
    },
    currentValue: {
      type: [Date, String],
      required: false,
      default: () => {
        "";
      },
    },
    label: {
      type: String,
      required: true,
      default: () => {
        "";
      },
    },
    validate: {
      type: Boolean,
      required: true,
    },
  });
  const date = ref(null);
  const isValid = ref(true);

  const name = computed(() => props.name);
  const validate = computed(() => props.validate);
  const currentValue = computed(() => props.currentValue);
  const rules = {
    date: { required },
  };
  const v$ = useVuelidate(rules, { date });
  const validateInput = () => {
    v$.value.$touch();
    isValid.value = !v$.value.$error;
    emit("is-valid", isValid.value);
  };

  const isError = computed(() => {
    if (!date.value && validate.value) {
      return "date__input--error date__input";
    }
    return "date__input";
  });

  watch(
    date,
    (value) => {
      emit("date-input", { name: name.value, value });
    },
    { deep: true }
  );
  watch(
    () => props.validate,
    (validate) => {
      if (validate) {
        setTimeout(() => {
          validateInput();
        }, 500);
      }
    }
  );
  watch(
    () => props.currentValue,
    (currentValue) => {
      if (currentValue) {
        date.value = currentValue;
      }
    }
  );
  if (currentValue.value && !date.value) {
    date.value = currentValue.value;
  }
</script>

<style lang="scss">
  .date {
    &__input {
      height: 48px;
      transition: all 0.3s ease;
      border: 1px solid #e1e6f0 !important;
      border-radius: 4px;
      flex-grow: 1;
      &--error {
        border: 1px solid #db4343 !important;
      }
    }
  }
  .dp__main {
    @apply md:tw-w-full;
  }
  .dp__input {
    border: 0;
    height: 46px;
  }
</style>
