<template>
  <Modal :show="true" @close-modal="$emit('close-spv-options')">
    <form
      @submit.prevent="setOption"
      class="spv-option tw-px-4 lg:tw-px-12 tw-font-normal"
    >
      <h1
        class="tw-font-CircularStd-medium tw-font-medium tw-mb-4 tw-text-xl tw-text-center tw-text-secondary"
      >
        Create an SPV
      </h1>
      <p
        class="tw-text-center tw-mt-4 tw-text-black-light tw-w-64 tw-mx-auto tw-mb-16 tw-max-w-[260px] tw-text-base tw-font-normal"
      >
        Choose how you would like to add an SPV to this account.
      </p>
      <div
        class="tw-flex spv-option__div tw-items-center tw-pl-4 tw-mb-4 tw-cursor-pointer"
        :class="[spvOption === 'old' ? 'spv-option__div--active' : null]"
        @click="spvOption = 'old'"
      >
        <input
          type="radio"
          name="spv"
          value="old"
          id="old"
          class="tw-mr-4 tw-w-5 tw-h-5"
          v-model="spvOption"
        />
        <p
          class="tw-text-lg spv-option__text tw-font-base"
          :class="[spvOption === 'old' ? 'spv-option__text--active' : null]"
        >
          I have an SPV
        </p>
      </div>
      <div @click="spvOption = 'new'">
        <!-- <router-link
          class="tw-flex spv-option__div tw-items-center tw-pl-4 tw-mb-4 tw-cursor-pointer"
          :class="[spvOption === 'new' ? 'spv-option__div--active' : null]"
          :to="{ name: 'DealInfo', params: { dealId } }"
        > -->
        <span
          class="tw-flex spv-option__div tw-items-center tw-pl-4 tw-mb-4 tw-cursor-pointer"
          :class="[spvOption === 'new' ? 'spv-option__div--active' : null]"
          @click="spvOption = 'new'"
        >
          <input
            type="radio"
            name="spv"
            id="new"
            value="new"
            class="tw-mr-4 tw-w-5 tw-h-5"
            v-model="spvOption"
          />
          <p
            class="tw-text-lg spv-option__text tw-font-base"
            :class="[spvOption === 'new' ? 'spv-option__text--active' : null]"
            @click="spvOption = 'new'"
          >
            I want to create an SPV
          </p>
        </span>
        <!-- </router-link> -->
      </div>
      <BtnComponent title="Continue" />
      <router-link
        v-if="dealId"
        class="tw-font-CircularStd-medium tw-font-medium tw-block spv-option__text spv-option__text--block tw-text-sm tw-text-center tw-mt-16 tw-cursor-pointer"
        :to="{ name: 'DealInfo', params: { dealId } }"
      >
        Skip for now
      </router-link>
    </form>
  </Modal>
</template>

<script setup>
  import Modal from "@/components/general/Modal";
  import BtnComponent from "@/components/general/BtnComponent";
  import { defineEmits, defineProps, ref } from "vue";
  import { useRouter } from "vue-router";
  defineEmits(["close-spv-options"]);
  const props = defineProps({
    dealId: {
      type: String,
      required: false,
      default: () => {
        return "";
      },
    },
  });

  const spvOption = ref(null);
  const router = useRouter();

  function setOption() {
    switch (spvOption.value) {
      case "old":
        router.push({
          name: "UploadSpv",
        });
        break;
      case "new":
        router.push({ name: "CreateSpv" });
        break;
    }
  }
</script>

<style lang="scss" scoped>
  .spv-option {
    &__div {
      background: #f7f9fd;
      border: 1px solid #e1e6f0;
      box-sizing: border-box;
      border-radius: 4px;
      transition: all 300ms ease-in;
      height: 48px;
      &--active,
      &:hover {
        border: 1px solid #4d84ff;
      }
    }
    &__text {
      color: #8791a7;
      transition: all 300ms ease-in;
      font-size: 14px;
      @media (min-width: 768px) {
        font-size: 16px;
      }
      &--active {
        color: #020b27;
      }
      &--block {
        display: block;
      }
    }
  }
</style>
