<template>
  <section
    class="tw-px-4 tw-w-full tw-box-border tw-max-w-[1000px] 2xl:tw-max-w-[1200px] tw-mx-auto"
  >
    <div
      class="md:tw-p-10"
      v-if="
        (!spvRequests || spvRequests?.length === 0) &&
          (!approvedSpvs || approvedSpvs?.length === 0)
      "
    >
      <SpvEmptyState />
    </div>
    <div v-else>
      <div class="tw-mb-20 md:tw-flex tw-justify-between">
        <div class="">
          <h1
            class="tw-font-bold tw-text-2xl md:tw-text-[28px] tw-text-secondary"
          >
            Special Purpose Vehicles
          </h1>
          <p class="tw-text-gray-light2 tw-text-base">
            Your list of SPV documents are shown here.
          </p>
        </div>
        <div class="sm:tw-flex">
          <div
            class="tw-flex tw-justify-end tw-items-center tw-mb-4 md:tw-mb-0"
          >
            <Search
              class="sm:tw-mr-2"
              :class="[currentMenu !== 'requests' ? 'sm:tw-mr-4' : null]"
              :showSearchField="showSearchField"
              @search-click="showSearchField = !showSearchField"
              :clearValue="resetSearch"
              @search-input="filterSpv"
            />
            <div
              class="tw-border-l tw-border-black-light tw-pl-2 sm:tw-mr-4 tw-h-6 tw-flex tw-items-center"
            >
              <Filter
                title="SPVs"
                v-if="currentMenu === 'requests'"
                :filterOptions="filterOptions"
                @filter-click="showFilterOptions = !showFilterOptions"
                :showFilterOptions="showFilterOptions"
                @filter-selected="filterSpv"
                @mouseleave="startTimeout"
                @mouseenter="deleteTimeout"
              />
            </div>
          </div>
          <CustomButton
            class="md:tw-max-w-[160px]"
            title="Create an SPV"
            @click="showOptions = true"
          />
        </div>
      </div>
      <nav
        class="tw-border-b tw-h-10 tw-border-[#D9DCE8] tw-w-full tw-max-w-[791px] tw-mb-10"
      >
        <button
          class="tw-w-max tw-h-full  tw-mr-12 tw-font-CircularStd-medium tw-font-medium tw-transition-all hover:tw-bg-transparent hover:tw-text-secondary tw-mr-10"
          :class="[
            currentMenu === menu.value
              ? 'tw-border-b-2 tw-border-primary tw-text-secondary'
              : 'tw-text-gray',
          ]"
          v-for="(menu, index) in spvMenu"
          :key="index"
          @click="currentMenu = menu.value"
        >
          {{ menu.name }}
        </button>
      </nav>
      <div>
        <SmallLoader v-if="loading" />
        <template v-else>
          <SpvCard
            v-for="spv in spvs"
            :key="spv._id"
            :spv="spv"
            @refresh-list="refreshList"
          />
          <SpvEmptyState
            v-if="!spvs || spvs?.length === 0"
            :spvType="currentMenu"
            :emptyType="emptyType"
          />
          <SpvOptionsModal
            v-if="showOptions"
            @close-spv-options="showOptions = false"
          />
        </template>
        <div class="tw-ml-auto tw-mt-7 tw-w-max" v-if="!filterValue && pages">
          <Pagination
            :currentPage="page"
            :totalPage="pages"
            @pageChange="changePage"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import filter from "@/mixins/filter";
  export default {
    mixins: [filter],
  };
</script>

<script setup>
  import SpvEmptyState from "@/components/spv/SpvEmptyState";
  import CustomButton from "@/components/general/BtnComponent.vue";
  import SpvCard from "@/components/spv/SpvCard";
  import Filter from "@/components/general/Filter";
  import Search from "@/components/general/Search";
  import SpvOptionsModal from "@/components/spv/SpvOptionsModal.vue";
  import SmallLoader from "@/components/notification/SmallLoader.vue";
  import Pagination from "@/components/navigation/Pagination.vue";
  import { useStore } from "vuex";
  import { computed, ref, watch } from "vue";
  import { useSearch } from "@/composables/search";

  const store = useStore();
  const { showSearchField } = useSearch();

  const spvMenu = [
    {
      name: "Your SPVs",
      value: "spvs",
    },
    {
      name: "SPV Requests",
      value: "requests",
    },
  ];
  const filterOptions = ref(["Pending", "Approved", "Rejected", "Terminated"]);
  const currentMenu = ref("spvs");
  const showFilterOptions = ref(false);
  // const showSearchField = ref(false);
  const filterValue = ref(null);
  const filterResult = ref(null);
  const showOptions = ref(false);
  const page = ref(1);
  const pages = ref(1);
  const limit = ref(7);
  const resetSearch = ref(false);

  const loading = computed(() => store.getters["loading"]);
  const spvRequests = computed(() => store.getters["Spv/spvRequests"]);
  const spvRequestsCount = computed(
    () => store.getters["Spv/spvRequestsCount"]
  );
  const currentSpvRequestsPage = computed(
    () => store.getters["Spv/currentSpvRequestsPage"]
  );
  const totalSpvRequestsPages = computed(
    () => store.getters["Spv/totalSpvRequestsPages"]
  );
  const approvedSpvs = computed(() => store.getters["Spv/spvs"]);
  const spvCount = computed(() => store.getters["Spv/spvCount"]);
  const CurrentSpvPage = computed(() => store.getters["Spv/CurrentSpvPage"]);
  const totalSpvPages = computed(() => store.getters["Spv/totalSpvPages"]);

  const spvs = computed(() => {
    let spvs = null;
    if (filterValue.value?.value) {
      spvs = filterResult.value;
    } else {
      switch (currentMenu.value) {
        case "spvs":
          spvs = approvedSpvs.value;
          break;
        case "requests":
          spvs = spvRequests.value;
          break;
      }
    }
    return spvs;
  });
  const emptyType = computed(() => {
    let type = null;
    if (filterValue.value) {
      if (filterValue.value.type) {
        type = "search";
      } else {
        type = "filter";
      }
    } else {
      type = "default";
    }
    return type;
  });

  const filterSpvDocByName = () => {
    const name = filterValue.value.value;
    const filteredDocs = approvedSpvs.value.filter((spv) =>
      spv.name.toLowerCase().includes(name)
    );
    return filteredDocs;
  };

  const filterSpvRequestByName = async () => {
    const { value: name } = filterValue.value;
    const response = await store.dispatch("Spv/searchSpvRequest", name);
    const { data: spv } = response.data;
    filterResult.value = spv;
  };
  const filterSpvRequestByStatus = async ({ value }) => {
    const params = {
      page: page.value,
      limit: limit.value,
      status: value,
    };
    await store.dispatch("Spv/fetchSpvRequests", params);
    filterResult.value = spvRequests.value;
  };

  const fetchSpvRequests = async () => {
    const params = {
      page: page.value,
      limit: limit.value,
      status: "Pending",
    };
    await store.dispatch("Spv/fetchSpvRequests", params);
  };
  const fetchSpvs = async () => {
    const params = {
      page: page.value,
      limit: limit.value,
      status: "completed",
    };
    await store.dispatch("Spv/fetchSpvs", params);
    pages.value = totalSpvPages.value;
  };
  const refreshList = () => {
    const menu = currentMenu.value;
    switch (menu) {
      case "requests":
        fetchSpvRequests();
        break;
      case "spvs":
        fetchSpvs();
        break;
    }
  };
  const filterSpv = (object) => {
    filterValue.value = object;
  };
  const changePage = (value) => {
    page.value = value;
  };
  watch(currentMenu, (value) => {
    //reset this value every time currentMenu changes
    filterValue.value = null;
    //change the pagination value to match the data for each menu
    // reset current page
    page.value = 1;
    switch (value) {
      case "requests":
        pages.value = totalSpvRequestsPages.value;
        break;
      case "spvs":
        pages.value = totalSpvPages.value;
        break;
    }
  });
  watch(filterValue, (filter) => {
    if (filter?.value) {
      const { type } = filter;
      if (type === "name") {
        filterSpvRequestByName();
      } else {
        filterSpvRequestByStatus(filter);
      }
    } else {
      filterValue.value = null;
      // this value is passed to the search component, when true, the search component would be prompted to clear the input box.
      resetSearch.value = true;
      fetchSpvRequests();
      setTimeout(() => {
        resetSearch.value = false;
      }, 500);
    }
  });
  watch(page, () => {
    const menu = currentMenu.value;
    switch (menu) {
      case "requests":
        fetchSpvRequests();
        break;
      case "spvs":
        fetchSpvs();
        break;
    }
  });
  fetchSpvRequests();
  fetchSpvs();
</script>

<style lang="scss" scoped></style>
