<template>
  <UploadSpvForm @spv-uploaded="openSpv" />
</template>

<script setup>
  import UploadSpvForm from "@/components/spv/forms/UploadSpvForm.vue";
  import { useRouter } from "vue-router";

  const router = useRouter();
  const openSpv = () => {
    router.push({ name: "Spv" });
  };
</script>

<style lang="scss">
  .upload-spv {
    border-radius: 4px;
    &__container {
      max-width: 818px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 100px;
    }
    &__form {
      background: #ffffff;
      border: 1px solid #f1f3f6;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
  .date {
    &__input {
      height: 48px;
      transition: all 0.3s ease;
      border: 1px solid #e1e6f0 !important;
      flex-grow: 1;
      &--error {
        border: 1px solid #db4343 !important;
      }
    }
  }
  .dp__main {
    @apply md:tw-w-full;
  }
  .dp__input {
    border: 0;
    height: 46px;
  }
</style>
