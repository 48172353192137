<template>
  <Modal :show="true" @close-modal="$emit('close-cancel-modal')">
    <div class="tw-max-w-[390px]">
      <img
        src="https://res.cloudinary.com/djalafcj9/image/upload/v1667563098/getequityV2/Group_35_spfcdv.png"
        class="tw-w-[84px] tw-h-[84px] tw-rounded-full tw-mx-auto tw-mb-4"
      />
      <h1
        class="tw-font-bold tw-text-xl tw-text-secondary tw-max-w-[243px] tw-mx-auto tw-text-center tw-mb-4"
      >
        {{ modalHeading }}
      </h1>

      <p
        class="tw-text-base tw-text-[#323E67] tw-text-center tw-max-w-[306px] tw-mx-auto"
      >
        You can create or add another SPV whenever you want
      </p>
      <div class="tw-flex tw-gap-x-4 tw-mt-16">
        <button
          @click="$emit('close-success-modal')"
          class="tw-rounded tw-w-full tw-bg-[#E6EDFF] tw-font-CircularStd-medium tw-font-medium tw-text-secondary tw-h-12"
        >
          Done
        </button>
      </div>
    </div>
  </Modal>
</template>

<script setup>
  import Modal from "@/components/general/Modal";
  import BtnComponent from "@/components/general/BtnComponent";
  import { useStore } from "vuex";
  import { computed } from "vue";
  defineEmits(["close-success-modal"]);

  const store = useStore();

  const { type } = defineProps({
    type: {
      type: String,
      required: true,
    },
  });
  const modalHeading = computed(() =>
    type === "Request"
      ? "Your SPV request has been cancelled"
      : "Your SPV has been deleted and removed"
  );
</script>

<style lang="scss" scoped></style>
