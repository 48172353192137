<template>
  <section
    class="tw-px-4 tw-w-full tw-box-border tw-max-w-[1000px] 2xl:tw-max-w-[1200px] tw-mx-auto tw-min-h-screen"
    v-if="spvInfo"
  >
    <SmallLoader v-if="loading" />
    <template v-else>
      <div class="tw-mb-8">
        <p class="tw-flex tw-gap-x-2 tw-text-gray-bg2 tw-items-center">
          <BackBtn /> Go back to SPVs
        </p>
      </div>
      <div class="tw-w-full md:tw-flex tw-items-center">
        <div class="tw-w-full md:tw-w-1/2">
          <div class="tw-border-b tw-border-black-light2 tw-pb-6">
            <h1 class="tw-text-secondary tw-font-bold tw-text-2xl tw-mb-1">
              {{ spvInfo.name }}
            </h1>
            <p class="tw-text-gray-light2 tw-text-base">
              Managed by {{ spvManager }}
            </p>
          </div>
          <div class="tw-flex tw-py-4 tw-w-full md:tw-gap-x-28">
            <div class="tw-w-1/2 md:tw-max-w-max">
              <p class="tw-text-gray-light2 tw-text-sm tw-mb-1">Country</p>
              <p
                class="tw-text-secondary tw-font-CircularStd-medium tw-font-medium"
              >
                {{ spvInfo.country }}
              </p>
            </div>
            <div class="tw-w-1/2 md:tw-max-w-max">
              <p class="tw-text-gray-light2 tw-text-sm tw-mb-1">
                Date of Creation
              </p>
              <p
                class="tw-text-secondary tw-font-CircularStd-medium tw-font-medium"
              >
                {{ formatISODate(spvInfo.createdAt) }}
              </p>
            </div>
          </div>
        </div>
        <router-link
          :to="{ name: 'LinkSpv', params: { id: spvInfo._id } }"
          class="tw-block md:tw-ml-auto tw-bg-primary tw-rounded tw-text-white tw-h-12 tw-w-max tw-px-4 tw-flex tw-items-center tw-mt-6 md:tw-mt-0 tw-font-CircularStd-medium tw-font-medium tw-text-base"
          >Link this SPV to a deal</router-link
        >
      </div>
      <div
        class="tw-rounded tw-bg-white tw-border tw-border-[#F1F3F6] tw-px-8 md:tw-px-12 tw-py-8 spv-info__div"
      >
        <h2
          class="tw-text-secondary tw-mb-3 tw-font-CircularStd-medium tw-font-medium tw-text-lg"
        >
          SPV Document
        </h2>
        <div class="tw-max-w-[642px]">
          <DocumentViewer :doc="{ name: spvInfo.name, url: spvInfo.url }" />
        </div>
        <div class="tw-mt-16">
          <h2
            class="tw-text-secondary tw-mb-3 tw-font-CircularStd-medium tw-font-medium tw-text-lg"
          >
            Deals linked to this SPV
          </h2>
          <div
            class="spv-info-list__div"
            v-if="spvDeals && spvDeals.length > 0"
          >
            <DealCard
              v-for="deal in spvDeals"
              :key="deal._id"
              :deal="deal"
              componentType="button"
              @click="
                $router.push({ name: 'DealInfo', params: { dealId: deal._id } })
              "
            />
          </div>
          <EmptyState v-else>
            <template v-slot:message>
              <p class="message tw-text-center tw-text-gray tw-my-3">
                No Deals have been linked to this SPV yet.
              </p></template
            >
          </EmptyState>
        </div>
      </div>
    </template>
  </section>
</template>

<script setup>
  import { computed } from "vue";
  import { useStore } from "vuex";
  import BackBtn from "@/components/general/BackBtn.vue";
  import { useRoute } from "vue-router";
  import { formatISODate } from "@/utils/helpers";
  import DocumentViewer from "@/components/general/DocumentViewer.vue";
  import DealCard from "@/components/deals/DealCard.vue";
  import EmptyState from "@/components/notification/EmptyState.vue";
  const route = useRoute();
  const store = useStore();

  const loading = computed(() => store.getters["loading"]);
  const spvInfo = computed(() => store.getters["Spv/spvInfo"]);
  const orgName = computed(
    () => store.state.organisationModule.organisationDetails.name
  );

  const spvManager = computed(() =>
    spvInfo.value.should_manage_spv ? "GetEquity" : orgName.value
  );
  const deals = computed(() => store.getters["Deals/dealRequests"]);
  const spvDeals = computed(() => {
    return (
      deals.value &&
      deals.value.filter((deal) => deal.spv === spvInfo.value._id)
    );
  });

  const fetchDeals = async () => {
    const query = { page: 1, limit: 1000 };
    await store.dispatch("Deals/getDealRequests", query);
  };

  const getSpvInfo = async () => {
    const id = route.params.id;
    await store.dispatch("Spv/fetchSpvRequest", id);
  };

  getSpvInfo();
  fetchDeals();
</script>

<style lang="scss" scoped>
  .spv-info {
    &__div {
      box-shadow: 0px 2px 4px rgba(44, 73, 137, 0.06);
    }
    &-list {
      &__div {
        @media screen and (min-width: 640px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 0.5rem;
        }

        @media screen and (min-width: 768px) {
          column-gap: 1rem;
        }

        @media screen and (min-width: 1024px) {
          grid-template-columns: repeat(3, 1fr);
          column-gap: 14px;
        }
      }
    }
  }
</style>
