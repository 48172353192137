<template>
  <Accordion :verified="false" :icon="icon" :info="info">
    <form @submit.prevent="checkFormValidation" class="">
      <div class="tw-mb-14">
        <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-flex-wrap">
          <!-- <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <h3
              class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
            >
              Country
            </h3>
            <Dropdown
              class=" tw-mt-2"
              label="Select Country"
              :options="spvCountries"
              :current-value="payload.country"
              name="country"
              :validate="validate"
              @input-changed="storeValue($event, index)"
              @is-valid="handleValidation"
            />
          </div> -->
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            v-if="
              payload.compliance.hasOwnProperty('tinNumber') &&
                !payload.compliance.shouldNorebaseProvideTin
            "
          >
            <h3
              class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
            >
              Tax Identification Number
            </h3>
            <InputField
              class=" tw-mt-2"
              label="Enter TIN"
              name="tinNumber"
              :required="!payload.compliance.shouldNorebaseProvideTin"
              :disabled="payload.compliance.shouldNorebaseProvideTin"
              :validate="validate"
              @input-changed="storeValue($event, index)"
              @is-valid="handleValidation"
            />
          </div>
          <div
            v-if="
              payload.compliance.hasOwnProperty('tinNumber') &&
                Object.keys(payload.compliance).includes(
                  'shouldNorebaseProvideTin'
                )
            "
            class="tw-w-full tw-mb-6 md:tw-mb-0 tw-bg-gray-lightest tw-px-6 tw-py-4  tw-text-sm tw-tracking-[-0.12px] tw-text-black-dark tw-cursor-pointer tw-rounded"
          >
            <div
              class="tw-flex tw-justify-between tw-items-center  tw-mb-2 tw-w-full"
            >
              <p
                class="tw-text-secondary tw-font-CircularStd-medium tw-font-medium tw-text-base"
              >
                Allow GetEquity create TIN for you
              </p>
              <ToggleButton
                :value="payload.compliance.shouldNorebaseProvideTin"
                @value-selected="setTin"
              />
            </div>
            <p class="tw-text-sm tw-text-black-light tw-max-w-[448px]">
              When turned on, GetEquity will be able to create a tax
              identification number for you. You will be charged
              <span
                class="tw-text-secondary tw-font-CircularStd-medium tw-font-medium"
                >300 USD</span
              >
              for this service.
            </p>
          </div>
        </div>
      </div>
      <div class="tw-mb-10">
        <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-flex-wrap">
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            v-if="payload.compliance.hasOwnProperty('numberOfEmployees')"
          >
            <h3
              class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
            >
              Number of Employees
            </h3>
            <InputField
              class=" tw-mt-2"
              type="number"
              :validateAsNumber="true"
              label="Enter Number"
              name="numberOfEmployees"
              :validate="validate"
              @input-changed="storeValue($event, index)"
              @is-valid="handleValidation"
            />
          </div>
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            v-if="payload.compliance.hasOwnProperty('financialYearEnd')"
          >
            <h3
              class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
            >
              Financial Year End
            </h3>
            <Calendar
              class=" tw-mt-2"
              :currentValue="payload.compliance.financialYearEnd"
              name="financialYearEnd"
              label="Select Date"
              :validate="validate"
              @date-input="storeValue"
              @is-valid="handleValidation"
            />
          </div>
        </div>
      </div>
      <div class="tw-mb-10">
        <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-flex-wrap">
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            v-if="payload.compliance.hasOwnProperty('proposedStartDate')"
          >
            <h3
              class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
            >
              Proposed Start Date
            </h3>
            <Calendar
              class=" tw-mt-2"
              :currentValue="payload.compliance.proposedStartDate"
              name="proposedStartDate"
              label="Select Date"
              :validate="validate"
              @date-input="storeValue"
              @is-valid="handleValidation"
            />
          </div>
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            v-if="payload.compliance.hasOwnProperty('estimatedTurnOver')"
          >
            <h3
              class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
            >
              Estimated Turnover (USD)
            </h3>
            <InputField
              :currentValue="estimatedTurnOver"
              class=" tw-mt-2"
              :validateAsNumber="true"
              label="Enter Figure"
              name="estimatedTurnOver"
              :validate="validate"
              @input-changed="storeValue($event, index)"
              @is-valid="handleValidation"
            />
          </div>
        </div>
      </div>
      <div
        class="tw-mb-10"
        v-if="payload.compliance.hasOwnProperty('companyIsSubsidiary')"
      >
        <div
          class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
        >
          <h3
            class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
          >
            Is this company a subsidiary?
          </h3>
          <div class="tw-flex tw-gap-x-8 tw-mt-3">
            <label
              for="yes"
              class="tw-text-sm tw-tracking-[-0.12px] tw-text-black-dark tw-flex tw-items-center tw-gap-x-2 tw-cursor-pointer"
            >
              <input
                v-model="payload.compliance.companyIsSubsidiary"
                type="radio"
                name="companyIsSubsidiary"
                :value="true"
                id="yes"
              />
              Yes</label
            >
            <label
              for="no"
              class="tw-text-sm tw-tracking-[-0.12px] tw-text-black-dark tw-flex tw-items-center tw-gap-x-2 tw-cursor-pointer"
            >
              <input
                v-model="payload.compliance.companyIsSubsidiary"
                type="radio"
                name="companyIsSubsidiary"
                :value="false"
                id="no"
              />
              No
            </label>
          </div>
        </div>
      </div>
      <div
        class="tw-mb-10"
        v-if="
          payload.compliance.hasOwnProperty(
            'resolutionAuthorizingIncorporationDocumentUrl'
          )
        "
      >
        <div
          class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-items-center tw-flex-wrap"
        >
          <h3
            class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
          >
            Resolution Authorizing Incorporation Document
          </h3>
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <InputField
              class=" tw-mt-2"
              label="Enter URL"
              name="resolutionAuthorizingIncorporationDocumentUrl"
              :currentValue="
                payload.compliance.resolutionAuthorizingIncorporationDocumentUrl
              "
              :validate="validate"
              @input-changed="storeValue"
            />
          </div>
          <p
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px] tw-h-8"
          >
            Enter link or upload document.
          </p>
          <DragNDrop
            accept="image/png,image/jpeg,image/jpg,.pdf"
            :currentValue="
              payload.compliance
                .resolutionAuthorizingIncorporationDocumentUrl === null
                ? ''
                : payload.compliance
                    .resolutionAuthorizingIncorporationDocumentUrl
            "
            :validate="validate"
            :startUpload="uploadFile"
            @re-upload="uploadFile = false"
            @file-added="
              setDoc($event, 'resolutionAuthorizingIncorporationDocumentUrl')
            "
            @is-valid="handleValidation"
            @upload-complete="
              setUpdatedUrl(
                $event,
                'resolutionAuthorizingIncorporationDocumentUrl'
              )
            "
            @upload-error="idUploadError"
          />
        </div>
      </div>
      <div
        class="tw-mb-10"
        v-if="payload.compliance.hasOwnProperty('companySecretary')"
      >
        <h3
          class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67] tw-w-full"
        >
          Company Secretary Details
        </h3>
        <div class="tw-mb-6 md:tw-flex tw-flex-wrap tw-gap-4 tw-mt-2">
          <div
            class="tw-w-full md:tw-w-2/3 tw-mb-6 md:tw-mb-0 md:tw-flex tw-gap-x-2 md:tw-max-w-[400px]"
          >
            <Dropdown
              class=" tw-mt-2 tw-w-full md:tw-max-w-[120px]"
              :currentValue="payload.compliance.companySecretary.title"
              label="Title"
              :options="titles"
              name="companySecretaryTitle"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
            <InputField
              class=" tw-mt-2 tw-w-full"
              label="First Name"
              :currentValue="payload.compliance.companySecretary.firstName"
              name="companySecretaryFirstName"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
          <div class="tw-w-full md:tw-w-1/3">
            <InputField
              class=" tw-mt-2"
              label="Middle Name"
              :currentValue="payload.compliance.companySecretary.middleName"
              name="companySecretaryMiddleName"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
        </div>
        <div
          class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-items-center tw-flex-wrap"
        >
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <InputField
              class=" tw-mt-2"
              label="Last Name"
              name="companySecretaryLastName"
              :currentValue="payload.compliance.companySecretary.lastName"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <Calendar
              class=" tw-mt-2"
              :currentValue="payload.compliance.companySecretary.dateOfBirth"
              name="companySecretaryDateOfBirth"
              label="Date of Birth"
              :validate="validate"
              @date-input="storeValue"
              @is-valid="handleValidation"
            />
          </div>
        </div>
        <div
          class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-items-center tw-flex-wrap"
        >
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <Dropdown
              class=" tw-mt-2"
              label="Gender"
              :options="gender"
              :current-value="payload.compliance.companySecretary.gender"
              name="companySecretaryGender"
              :validate="validate"
              @input-changed="storeValue($event, index)"
              @is-valid="handleValidation"
            />
          </div>
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <InputField
              class=" tw-mt-2"
              label="Email Address"
              name="companySecretaryEmailAddress"
              :currentValue="payload.compliance.companySecretary.emailAddress"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
        </div>
        <div
          class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-items-center tw-flex-wrap"
        >
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <PhoneInput
              class=" tw-mt-2"
              label="Phone Number"
              :currentValue="payload.compliance.companySecretary.phoneNumber"
              name="companySecretaryPhoneNumber"
              :validate="validate"
              @phone-input="storeValue"
              @is-valid="handleValidation"
            />
          </div>
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <Dropdown
              class=" tw-mt-2"
              label="Nationality"
              :options="nationality"
              :currentValue="payload.compliance.companySecretary.nationality"
              name="companySecretaryNationality"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
        </div>
        <div
          class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-items-center tw-flex-wrap"
        >
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <InputField
              class=" tw-mt-2"
              label="Occupation"
              name="companySecretaryOccupation"
              :currentValue="payload.compliance.companySecretary.occupation"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <Dropdown
              class=" tw-mt-2"
              label="Means of Identification"
              :options="identificationTypes"
              :currentValue="
                payload.compliance.companySecretary.meansOfIdentification
              "
              name="companySecretaryMeansOfIdentification"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
        </div>

        <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2">
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <Dropdown
              class=" tw-mt-2"
              label="Country of Identification"
              :options="countries"
              :currentValue="payload.compliance.companySecretary.countryOfIssue"
              name="companySecretaryCountryOfIssue"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <InputField
              class=" tw-mt-2"
              label="Identification Number"
              :currentValue="
                payload.compliance.companySecretary.identificationNumber
              "
              name="companySecretaryIdentificationNumber"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
        </div>
        <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2">
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <InputField
              class=" tw-mt-2"
              label="Residential Address"
              :currentValue="
                payload.compliance.companySecretary.residentialAddress
              "
              name="companySecretaryResidentialAddress"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <Dropdown
              class=" tw-mt-2"
              label="Country"
              :options="countries"
              :currentValue="payload.compliance.companySecretary.country"
              name="companySecretaryCountry"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
        </div>
        <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2">
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <InputField
              class=" tw-mt-2"
              label="Number of Alloted Shares"
              :currentValue="
                payload.compliance.companySecretary.numberOfAllottedShares
              "
              name="companySecretaryNumberOfAllottedShares"
              type="number"
              :validateAsNumber="true"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <Dropdown
              class=" tw-mt-2"
              label="Class of Shares"
              :options="classOfShares"
              :currentValue="payload.compliance.companySecretary.classOfShares"
              name="companySecretaryClassOfShares"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
        </div>
        <div
          class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-items-center tw-flex-wrap"
        >
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <h3
              class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67] tw-w-full"
            >
              Company Secretary ID Upload
            </h3>
            <InputField
              class=" tw-mt-2"
              label="Enter URL"
              name="companySecretaryIdentificationDocumentUrl"
              :currentValue="
                payload.compliance.companySecretary.identificationDocumentUrl
              "
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
          <p
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px] tw-h-8"
          >
            Enter link or upload document.
          </p>
          <DragNDrop
            accept="image/png,image/jpeg,image/jpg,.pdf"
            :currentValue="
              payload.compliance.companySecretary.identificationDocumentUrl ===
              null
                ? ''
                : payload.compliance.companySecretary.identificationDocumentUrl
            "
            :validate="validate"
            :startUpload="uploadFile"
            @re-upload="uploadFile = false"
            @file-added="
              setDoc($event, 'companySecretaryIdentificationDocumentUrl')
            "
            @is-valid="handleValidation"
            @upload-complete="
              setUpdatedUrl($event, 'companySecretaryIdentificationDocumentUrl')
            "
            @upload-error="idUploadError"
          />
        </div>
        <div
          class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-items-center tw-flex-wrap"
        >
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <h3
              class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67] tw-w-full"
            >
              Company Secretary Signature Upload
            </h3>
            <InputField
              class=" tw-mt-2"
              label="Enter URL"
              name="companySecretarySignatureUrl"
              :currentValue="payload.compliance.companySecretary.signatureUrl"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
          <p
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px] tw-h-8"
          >
            Enter link or upload document.
          </p>
          <DragNDrop
            accept="image/png,image/jpeg,image/jpg,.pdf"
            :currentValue="
              payload.compliance.companySecretary.signatureUrl === null
                ? ''
                : payload.compliance.companySecretary.signatureUrl
            "
            :validate="validate"
            :startUpload="uploadFile"
            @re-upload="uploadFile = false"
            @file-added="setDoc($event, 'companySecretarySignatureUrl')"
            @is-valid="handleValidation"
            @upload-complete="
              setUpdatedUrl($event, 'companySecretarySignatureUrl')
            "
            @upload-error="idUploadError"
          />
        </div>
        <div
          class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-items-center tw-flex-wrap"
        >
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <h3
              class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67] tw-w-full"
            >
              Company Secretary Passport photo
            </h3>
            <InputField
              class=" tw-mt-2"
              label="Enter URL"
              name="companySecretaryPassportPhotoUrl"
              :currentValue="
                payload.compliance.companySecretary.passportPhotoUrl
              "
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
          <p
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px] tw-h-8"
          >
            Enter link or upload document.
          </p>
          <DragNDrop
            accept="image/png,image/jpeg,image/jpg,.pdf"
            :currentValue="
              payload.compliance.companySecretary.passportPhotoUrl === null
                ? ''
                : payload.compliance.companySecretary.passportPhotoUrl
            "
            :validate="validate"
            :startUpload="uploadFile"
            @re-upload="uploadFile = false"
            @file-added="setDoc($event, 'companySecretaryPassportPhotoUrl')"
            @is-valid="handleValidation"
            @upload-complete="
              setUpdatedUrl($event, 'companySecretaryPassportPhotoUrl')
            "
            @upload-error="idUploadError"
          />
        </div>
      </div>
      <div
        class="tw-mb-10"
        v-if="
          payload.compliance.hasOwnProperty('CEONonCriminalRecordDocumentUrl')
        "
      >
        <div
          class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-items-center tw-flex-wrap"
        >
          <h3
            class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67] tw-w-full"
          >
            Upload CEO non-criminal record document
          </h3>
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <InputField
              class=" tw-mt-2"
              label="Enter URL"
              name="CEONonCriminalRecordDocumentUrl"
              :currentValue="payload.compliance.CEONonCriminalRecordDocumentUrl"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
          <p
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px] tw-h-8"
          >
            Enter link or upload document.
          </p>
          <DragNDrop
            accept="image/png,image/jpeg,image/jpg,.pdf"
            :currentValue="payload.compliance.CEONonCriminalRecordDocumentUrl"
            :validate="validate"
            :startUpload="uploadFile"
            @re-upload="uploadFile = false"
            @file-added="setDoc($event, 'CEONonCriminalRecordDocumentUrl')"
            @is-valid="handleValidation"
            @upload-complete="
              setUpdatedUrl($event, 'CEONonCriminalRecordDocumentUrl')
            "
            @upload-error="idUploadError"
          />
        </div>
      </div>
      <div class="tw-mb-10" v-if="payload.compliance.hasOwnProperty('manager')">
        <div
          class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-items-center tw-flex-wrap"
        >
          <h3
            class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67] tw-w-full"
          >
            Manager Information
          </h3>

          <div class="tw-w-full tw-mb-6 md:tw-mb-0 ">
            <InputField
              :currentValue="payload.compliance.manager.name"
              class="tw-mt-2"
              label="Full Name"
              name="managerName"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
          <div
            class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-items-center tw-flex-wrap"
          >
            <h3
              class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67] tw-w-full"
            >
              Upload ID Document for Manager
            </h3>
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                label="Enter URL"
                name="photoOfIDDocument"
                :currentValue="payload.compliance.photoOfIDDocument"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <p
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px] tw-h-8"
            >
              Enter link or upload document.
            </p>
            <DragNDrop
              accept="image/png,image/jpeg,image/jpg,.pdf"
              :currentValue="payload.compliance.manager.photoOfIDDocument"
              :validate="validate"
              :startUpload="uploadFile"
              @re-upload="uploadFile = false"
              @file-added="setDoc($event, 'photoOfIDDocument')"
              @is-valid="handleValidation"
              @upload-complete="setUpdatedUrl($event, 'photoOfIDDocument')"
              @upload-error="idUploadError"
            />
          </div>
          <div
            class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-items-center tw-flex-wrap"
          >
            <h3
              class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67] tw-w-full"
            >
              Upload Manager non-criminal record document
            </h3>
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                label="Enter URL"
                name="nonCriminalRecordDocumentUrl"
                :currentValue="payload.compliance.nonCriminalRecordDocumentUrl"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <p
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px] tw-h-8"
            >
              Enter link or upload document.
            </p>
            <DragNDrop
              accept="image/png,image/jpeg,image/jpg,.pdf"
              :currentValue="
                payload.compliance.manager.nonCriminalRecordDocumentUrl
              "
              :validate="validate"
              :startUpload="uploadFile"
              @re-upload="uploadFile = false"
              @file-added="setDoc($event, 'nonCriminalRecordDocumentUrl')"
              @is-valid="handleValidation"
              @upload-complete="
                setUpdatedUrl($event, 'nonCriminalRecordDocumentUrl')
              "
              @upload-error="idUploadError"
            />
          </div>
        </div>
      </div>
      <div
        class="tw-w-full tw-flex tw-mb-8 tw-rounded tw-bg-orange-lightest tw-px-8 tw-py-4 tw-gap-x-5"
        v-if="
          payload.compliance.hasOwnProperty(
            'shouldNorebaseProvideResidentDirector'
          ) &&
            payload.compliance.hasOwnProperty(
              'shouldNorebaseProvideResidentCompanySecretary'
            )
        "
      >
        <img
          class="tw-w-12 tw-h-12"
          src="https://res.cloudinary.com/djalafcj9/image/upload/v1643122295/getequityV2/investor/Alert_qkaxgo.svg"
        />
        <div>
          <p
            class="tw-text-secondary tw-font-medium tw-font-CircularStd-medium tw-mb-2"
          >
            GetEquity will provide resident director and company secretary.
          </p>
          <p class="tw-text-xs tw-text-gray-light2 tw-max-w-[408px]">
            Here, we shall tell the user why GetEquity is providing resident
            director and company secretary.
          </p>
        </div>
      </div>
      <div class="tw-h-12 tw-w-[130px]">
        <CustomButton title="Continue" :loading="loading" :disabled="loading" />
      </div>
    </form>
  </Accordion>
</template>
<script setup>
  import Accordion from "@/components/setup/Accordion";
  import InputField from "@/components/general/InputField";
  import CustomButton from "@/components/general/BtnComponent";
  import Calendar from "@/components/general/DatePicker.vue";

  import Dropdown from "@/components/general/Dropdown.vue";
  import DragNDrop from "@/components/general/DragNDrop";
  import TextField from "@/components/general/TextField.vue";
  import PhoneInput from "@/components/general/PhoneInput.vue";
  import LinkInput from "@/components/general/LinkInput.vue";
  import ToggleButton from "@/components/general/ToggleButton.vue";
  import nationality from "@/utils/json/nationality.json";
  import SpvCompliance from "@/utils/json/spvCompliance.json";
  import { useStore } from "vuex";
  import { useValidation } from "@/composables/formValidation";

  import { ref, computed, watch } from "vue";
  import {
    formatAmountToDollar,
    convertToLocale,
    strip,
  } from "@/utils/helpers";
  import countryRegionState from "@/utils/countryRegionData";
  import { useRoute } from "vue-router";

  const emit = defineEmits(["is-active", "submit"]);

  const store = useStore();
  const route = useRoute();

  const {
    fields,
    handleValidation,
    checkFormValidation,
    validate,
    canSubmit,
  } = useValidation();

  nationality;
  countryRegionState;
  const info = ref({
    title: "Compliance",
  });
  const icon = ref(
    "https://res.cloudinary.com/djalafcj9/image/upload/v1668692227/getequityV2/inv-memo_tf6gnm.svg"
  );

  const payload = ref({
    country: null,
    compliance: {},
  });
  const estimatedTurnOver = ref(null);
  const isValidated = ref(false);
  const uploadLoading = ref(false);
  const uploadFile = ref(false);
  const gender = ref(["Male", "Female"]);
  const uploaded = ref([]);
  const identificationTypes = ref([
    "National ID card",
    "Passport Data Page",
    "Driver's License",
  ]);
  const filesToUpload = ref(); // number of file uploads on the form

  const classOfShares = ref(["Common", "preferred"]);

  const titles = ["Mr", "Miss", "Mrs"];
  const loading = computed(() => store.getters["loading"]);
  const spvCountries = computed(() => store.getters["Spv/spvCountries"]);
  const spvInfo = computed(() => store.getters["Spv/spvInfo"]);

  const countries = computed(() =>
    countryRegionState.map((country) => `${country.countryName}`)
  );

  const defaultValueFields = ref(0); // number of fields that are default

  const estimatedTurnOverFormatted = computed(() =>
    convertToLocale(Number(strip(estimatedTurnOver.value)))
  );

  const storeValue = ({ name, value }) => {
    if (name === "country") {
      payload.value.country = value;
    } else if (name === "estimatedTurnOver") {
      estimatedTurnOver.value = value;
    } else if (name.includes("companySecretary")) {
      const key = name.split("companySecretary")[1];
      const newKey = key.charAt(0).toLowerCase() + key.slice(1);
      if (newKey === "phoneNumber") {
        let number = `${value.code}${value.number}`;
        number = String(number)
          .split(" ")
          .join("");
        payload.value.compliance.companySecretary[newKey] = number;
      } else {
        payload.value.compliance.companySecretary[newKey] = value;
      }
    } else if (
      name === "photoOfIDDocument" ||
      name === "nonCriminalRecordDocumentUrl"
    ) {
      payload.value.compliance.manager[name] = value;
    } else if (name === "managerName") {
      payload.value.compliance.manager.name = value;
    } else {
      payload.value.compliance[name] = value;
    }
  };
  const setTin = (bool) => {
    payload.value.compliance.shouldNorebaseProvideTin = bool;
  };
  const setActiveTab = (title) => {
    emit("is-active", title);
  };
  const setDoc = (url, name) => {
    if (
      name === "photoOfIDDocument" ||
      name === "nonCriminalRecordDocumentUrl"
    ) {
      payload.value.compliance.manager[name] = url;
    } else if (name.includes("companySecretary")) {
      const key = name.split("companySecretary")[1];
      const newKey = key.charAt(0).toLowerCase() + key.slice(1);
      payload.value.compliance.companySecretary[newKey] = url;
    } else {
      payload.value.compliance[name] = url;
    }
  };

  const setUpdatedUrl = (url, name) => {
    setDoc(url, name);
    // push a boolean/item to this array after Cloudinary upload is complete
    uploaded.value.push(true);
    // compare array length with the number of file uploads on the form
    if (uploaded.value.length === filesToUpload.value) {
      uploadLoading.value = false;
      uploadFile.value = false;
      emit("submit", { payload: payload.value, id: route.params.id });
    }
  };
  const idUploadError = () => {
    uploadFile.value = false;
    uploadLoading.value = false;
    store.dispatch("setLoading", false);
  };

  const getNumberOfUpload = () => {
    // TO DO
    // abstract into a composable
    const dragDrop = Array.from(document.getElementsByClassName("dragDrop"));
    const filesLength = dragDrop.length;
    filesToUpload.value = filesLength;
  };

  const getSpvRequest = async () => {
    const id = route.params.id;
    await store.dispatch("Spv/fetchSpvRequest", id);
  };

  const getDefaultValue = (key) => {
    let value = null;
    switch (key) {
      case "shouldNorebaseProvideTin":
        value = true;
        defaultValueFields.value += 1;
        break;
      case "companySecretary":
        value = {
          title: "",
          firstName: "",
          middleName: "",
          lastName: "",
          dateOfBirth: "",
          gender: "",
          emailAddress: "",
          phoneNumber: "",
          nationality: "",
          occupation: "",
          meansOfIdentification: "",
          countryOfIssue: "",
          identificationNumber: "",
          identificationDocumentUrl: "",
          passportPhotoUrl: "",
          signatureUrl: "",
          numberOfAllottedShares: "",
          classOfShares: "",
          residentialAddressIsPostalAddress: true,
          residentialAddress: "",
        };
        break;
      case "shouldNorebaseProvideResidentDirector":
        value = true;
        defaultValueFields.value += 1;
        break;
      case "tinNumber":
        value = "0000";
        break;
      case "companyIsSubsidiary":
        value = false;
        break;
      case "shouldNorebaseProvideResidentCompanySecretary":
        value = true;
        defaultValueFields.value += 1;
        break;
      case "provideAuditorForMe":
        value = true;
        defaultValueFields.value += 1;
        break;
      case "manager":
        value = {
          name: "",
          photoOfIDDocument: "",
          nonCriminalRecordDocumentUrl: "",
        };
        break;
    }
    return value;
  };
  //TO DO
  // 1. Abstract function into a composable
  // 2. Add logic that check if every 'value' is an object
  // 3. If (2) is true, loop through object using Object.Keys and increment fieldsNo by it's length
  const getNumberOfFields = () => {
    let fieldsNo = 0;
    Object.values(payload.value.compliance).map((value) => {
      if (typeof value === "object" && value !== null) {
        fieldsNo += Object.keys(value).length;
      } else {
        fieldsNo += 1;
      }
    });
    fields.value = fieldsNo;
  };

  const checkComplianceObject = () => {
    const complianceKeys = Object.keys(payload.value.compliance);
    complianceKeys.map((key) => {
      switch (key) {
        case "tinNumber":
          if (payload.value.compliance.shouldNorebaseProvideTin) {
            handleValidation(true);
          }
          break;
        case "auditorDetails":
          handleValidation(true);
          break;
        case "companyIsSubsidiary":
          handleValidation(true);
          break;
      }
    });
    for (let index = 0; index < defaultValueFields.value; index++) {
      handleValidation(true);
    }
  };

  watch(
    () => payload.value.country,
    (country) => {
      const complianceArray = SpvCompliance[country];
      const complianceObject = {};
      complianceArray.map((index) => {
        if (
          index !== "residentCompanySecretary" &&
          index !== "residentDirector" &&
          index !== "provideAuditorForMe"
        ) {
          complianceObject[index] = getDefaultValue(index);
        }
      });
      payload.value.compliance = complianceObject;
      getNumberOfFields();
      setTimeout(() => {
        getNumberOfUpload();
      }, 100);
    }
  );
  watch(estimatedTurnOverFormatted, (value) => {
    estimatedTurnOver.value = value;
    payload.value.compliance.estimatedTurnOver = strip(value);
  });
  watch(spvInfo, (spvInfo) => {
    payload.value.country = spvInfo.country;
  });
  watch(validate, (value) => {
    if (value) {
      getNumberOfUpload();
      checkComplianceObject();
      isValidated.value = true;
    }
  });
  watch(canSubmit, (value) => {
    if (value) {
      uploaded.value = [];
      uploadFile.value = true;
      uploadLoading.value = true;
    }
  });
  getSpvRequest();
</script>
