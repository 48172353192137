<template>
  <Accordion
    :verified="false"
    :icon="icon"
    :info="info"
    @is-active="setActiveTab"
  >
    <form @submit.prevent="checkFormValidation" class="">
      <div class="tw-mb-10">
        <div class="tw-mt-10">
          <h3
            class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
          >
            Basic Information
          </h3>
          <div
            class="tw-mb-6 md:tw-flex tw-flex-wrap tw-gap-4 tw-mt-2 tw-justify-between"
          >
            <div
              class="tw-w-full md:tw-w-1/3 tw-mb-6 md:tw-mb-0 md:tw-max-w-[120px]"
            >
              <Dropdown
                class="tw-mt-2"
                :currentValue="director.title"
                label="Title"
                :options="titles"
                name="title"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <div class="tw-w-full md:tw-w-2/3 md:tw-max-w-[240px]">
              <InputField
                class=" tw-mt-2"
                label="Designation"
                :currentValue="director.designation"
                name="designation"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <div class="tw-w-full md:tw-w-1/3 md:tw-max-w-[240px]">
              <InputField
                class=" tw-mt-2"
                label="First Name"
                :currentValue="director.firstName"
                name="firstName"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
          </div>
          <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2">
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                label="Middle Name"
                :currentValue="director.middleName"
                name="middleName"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <div
              class="tw-w-full md:tw-w-1/2 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                label="Last Name"
                :currentValue="director.lastName"
                name="lastName"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
          </div>
          <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2">
            <div
              class="tw-w-full md:tw-w-1/2 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <Calendar
                class=" tw-mt-2"
                :currentValue="director.dob"
                name="dob"
                label="Date of Birth"
                :validate="validate"
                @date-input="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <Dropdown
                class=" tw-mt-2"
                label="Gender"
                :options="gender"
                name="gender"
                :currentValue="director.gender"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
          </div>
          <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2">
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                type="email"
                class=" tw-mt-2"
                label="Email"
                name="email"
                :currentValue="director.email"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <PhoneInput
                class=" tw-mt-2"
                label="Phone Number"
                :currentValue="director.phoneNumber"
                name="phoneNumber"
                :validate="validate"
                @phone-input="storeValue"
                @is-valid="handleValidation"
              />
            </div>
          </div>
        </div>

        <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2">
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <Dropdown
              class=" tw-mt-2"
              label="Nationality"
              :options="nationality"
              :currentValue="director.nationality"
              name="nationality"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
          <div
            class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
          >
            <InputField
              class=" tw-mt-2"
              label="Occupation"
              :currentValue="director.occupation"
              name="occupation"
              :validate="validate"
              @input-changed="storeValue"
              @is-valid="handleValidation"
            />
          </div>
        </div>
        <div class="tw-mt-10">
          <h3
            class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
          >
            Identification Document
          </h3>
          <!--- <div
            class="tw-flex tw-flex-wrap tw-gap-y-3 tw-mb-8 lg:tw-gap-y-0 tw-gap-x-6"
          >
            <div
              class="tw-text-[#323E67] tw-text-sm tw-cursor-pointer tw-flex tw-items-center tw-gap-x-1 tw-mt-4"
              v-for="(doc, index) in identificationTypes"
              :key="index"
              @click="director.countryOfIdentification = doc.value"
            >
              <input
                class="tw-border-primary"
                type="radio"
                name="Identification Document"
                :id="doc.value"
                :value="doc.value"
                v-model="director.countryOfIdentification"
              />
              {{ doc.name }}
            </div>
          </div> -->
          <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2">
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <Dropdown
                class=" tw-mt-2"
                label="Country of Identification"
                :options="countries"
                :currentValue="director.countryOfIdentification"
                name="countryOfIdentification"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                label="Identification Number"
                :currentValue="director.identificationNumber"
                name="identificationNumber"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
          </div>
          <div
            class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-items-center tw-flex-wrap"
          >
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                label="Identification Doc URL"
                name="identificationUrl"
                :currentValue="director.identificationUrl"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <p
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px] tw-h-8"
            >
              Enter link or upload document.
            </p>
            <DragNDrop
              accept="image/png,image/jpeg,image/jpg,.pdf"
              :currentValue="director.identificationUrl"
              :validate="validate"
              :startUpload="uploadFile"
              @re-upload="uploadFile = false"
              @file-added="setDoc($event, 'identificationUrl')"
              @is-valid="handleValidation"
              @upload-complete="setUpdatedUrl($event, 'identificationUrl')"
              @upload-error="idUploadError"
            />
          </div>
        </div>
        <div class="tw-mt-10">
          <h3
            class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
          >
            Signature
          </h3>
          <div
            class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-flex-wrap tw-items-center"
          >
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                label="Signature attachment URL"
                name="signatureUrl"
                :currentValue="director.signatureUrl"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <p
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px] tw-h-8"
            >
              Enter link or upload document.
            </p>
            <DragNDrop
              accept="image/png,image/jpeg,image/jpg,.pdf"
              :currentValue="director.signatureUrl"
              :validate="validate"
              :startUpload="uploadFile"
              @re-upload="uploadFile = false"
              @file-added="setDoc($event, 'signatureUrl')"
              @is-valid="handleValidation"
              @upload-complete="setUpdatedUrl($event, 'signatureUrl')"
              @upload-error="idUploadError"
            />
          </div>
        </div>
        <div class="tw-mt-10">
          <h3
            class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
          >
            Passport
          </h3>
          <div
            class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2 tw-flex-wrap tw-items-center"
          >
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                label="passport Doc URL"
                name="passportUrl"
                :currentValue="director.passportUrl"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
            <p
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px] tw-h-8"
            >
              Enter link or upload document.
            </p>
            <DragNDrop
              accept="image/png,image/jpeg,image/jpg,.pdf"
              :currentValue="director.passportUrl"
              :validate="validate"
              :startUpload="uploadFile"
              @re-upload="uploadFile = false"
              @file-added="setDoc($event, 'passportUrl')"
              @is-valid="handleValidation"
              @upload-complete="setUpdatedUrl($event, 'passportUrl')"
              @upload-error="idUploadError"
            />
          </div>
        </div>

        <div class="tw-mt-12">
          <h3
            class="tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67]"
          >
            Residential Address
          </h3>
          <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-mt-2">
            <div
              class="tw-w-full md:tw-w-1/2 tw-mb-6 md:tw-mb-0 md:tw-max-w-[237px] lg:tw-max-w-[306px]"
            >
              <InputField
                class=" tw-mt-2"
                label="Address"
                :currentValue="director.residentialAddress"
                name="residentialAddress"
                :validate="validate"
                @input-changed="storeValue"
                @is-valid="handleValidation"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="tw-h-12 tw-w-[130px]">
        <CustomButton title="Continue" :loading="loading" :disabled="loading" />
      </div>
    </form>
  </Accordion>
</template>
<script setup>
  import Accordion from "@/components/setup/Accordion";
  import InputField from "@/components/general/InputField";
  import Calendar from "@/components/general/DatePicker.vue";
  import CustomButton from "@/components/general/BtnComponent";
  import Dropdown from "@/components/general/Dropdown.vue";
  import DragNDrop from "@/components/general/DragNDrop";
  import TextField from "@/components/general/TextField.vue";
  import PhoneInput from "@/components/general/PhoneInput.vue";
  import LinkInput from "@/components/general/LinkInput.vue";
  import nationality from "@/utils/json/nationality.json";
  import countryRegionState from "@/utils/countryRegionData";
  import { useStore } from "vuex";
  import { useValidation } from "@/composables/formValidation";
  import { inject, ref, computed, watch } from "vue";
  import { setItem, getItem } from "@/utils/storage";

  const emit = defineEmits(["is-active", "submit"]);

  const store = useStore();

  const {
    fields,
    handleValidation,
    checkFormValidation,
    validate,
    canSubmit,
  } = useValidation();

  nationality;
  countryRegionState;
  const activeTab = inject("activeTab");
  const info = ref({
    title: "Director",
  });
  const icon = ref(
    "https://res.cloudinary.com/djalafcj9/image/upload/v1673536223/getequityV2/directors_uea3bf.svg"
  );
  const director = ref({
    designation: null,
    title: null,
    firstName: null,
    lastName: null,
    middleName: null,
    dob: null,
    gender: null,
    email: null,
    phoneNumber: null,
    nationality: null,
    occupation: null,
    countryOfIdentification: null,
    identificationNumber: null,
    identificationUrl: "",
    passportUrl: "",
    signatureUrl: "",
    residentialAddress: null,
  });
  const titles = ["Mr", "Miss", "Mrs"];
  const gender = ref(["Male", "Female"]);
  const uploadFile = ref(false);
  const uploaded = ref([]);
  const uploadLoading = ref(false);
  const filesToUpload = ref(3); // number of file uploads on the form
  const identificationTypes = ref([
    { name: "National ID card", value: "nationalId" },
    { name: "Passport Data Page", value: "Passport" },
    { name: "Driver's License", value: "driverLicense" },
  ]);

  const loading = computed(() => store.getters["loading"]);

  const countries = computed(() =>
    countryRegionState.map((country) => `${country.countryName}`)
  );

  const storeValue = ({ name, value }) => {
    if (name === "phoneNumber") {
      let number = `${value.code}${value.number}`;
      number = String(number)
        .split(" ")
        .join("");
      director.value.phoneNumber = number;
    } else {
      director.value[name] = value;
    }
  };
  const setActiveTab = (title) => {
    emit("is-active", title);
  };
  const setDoc = (url, name) => {
    director.value[name] = url;
  };

  const setUpdatedUrl = (url, name) => {
    director.value[name] = url;
    // push a boolean/item to this array after Cloudinary upload is complete
    uploaded.value.push(true);
    // compare array length with the number of file uploads on the form
    if (uploaded.value.length === filesToUpload.value) {
      uploadLoading.value = false;
      uploadFile.value = false;
      emit("submit");
    }
  };
  const idUploadError = () => {
    uploadFile.value = false;
    uploadLoading.value = false;
    store.dispatch("setLoading", false);
  };
  const fetchFormData = () => {
    const data = getItem(info.value.title);
    if (data) {
      director.value = data;
    }
  };
  watch(
    director,
    (value) => {
      setItem(info.value.title, value);
    },
    { deep: true }
  );
  watch(canSubmit, (value) => {
    if (value) {
      uploaded.value = [];
      uploadFile.value = true;
      uploadLoading.value = true;
    }
  });
  fields.value = Object.keys(director.value).length;
  fetchFormData();
</script>
