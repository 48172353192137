<template>
  <div
    class="tw-bg-white tw-rounded tw-border tw-border-[#f1f3f6] tw-px-4 md:tw-px-8 tw-py-6 tw-mb-5 tw-grid tw-grid-cols-[20% 80%] tw-flex-row tw-gap-y-3 md:tw-flex tw-gap-x-5"
  >
    <div class="tw-w-12 tw-h-12 tw-col-span-1">
      <img src="@/assets/img/spv.svg" />
    </div>
    <div class="">
      <p
        class="tw-font-CircularStd-medium tw-text-medium tw-text-secondary tw-text-lg"
      >
        {{ spv.name || spv.proposed_name1 }}
      </p>
      <p
        class="tw-text-[15px] tw-text-gray-light2"
        v-if="spv.should_manage_spv"
      >
        Managed by GetEquity
      </p>
      <p class="tw-text-[15px] tw-text-gray-light2" v-else>
        Managed by {{ orgName }}
      </p>

      <div
        class="tw-font-CircularStd-medium tw-text-medium tw-rounded tw-px-4 tw-py-2 tw-w-max tw-h-10 tw-text-sm tw-mt-6"
        :class="spvTypeClass"
      >
        {{ spvType }}
      </div>
    </div>
    <div
      class="tw-border-t tw-border-[#d9dce8] md:tw-border-l md:tw-border-t-0 md:tw-pl-12 md:tw-ml-auto md:tw-w-1/2 tw-flex tw-justify-between md:tw-justify-start tw-gap-x-5 lg:tw-gap-x-12 tw-col-span-2"
    >
      <div class="tw-mt-2 md:tw-mt-0">
        <p class="tw-text-sm tw-text-gray-light2 tw-mb-2">Country</p>
        <p
          class="tw-font-CircularStd-medium tw-text-medium tw-text-secondary tw-text-base"
        >
          {{ spvCountry }}
        </p>
        <router-link
          :to="{ name: 'SpvBusinessInfo', params: { id: spv.id } }"
          class="tw-font-CircularStd-medium tw-text-medium tw-text-base tw-underline tw-text-[#2A62F5] tw-mt-8 hover:tw-bg-white hover:tw-text-[#2A62F5] tw-block"
          v-if="spv.status === 'Pending'"
        >
          Edit SPV Request
        </router-link>
        <router-link
          class="tw-font-CircularStd-medium tw-text-medium tw-text-base tw-underline tw-text-[#2A62F5] tw-mt-8 tw-block hover:tw-bg-white hover:tw-text-[#2A62F5]"
          :to="{ name: 'SpvInfo', params: { id: spv._id } }"
          v-else
        >
          View SPV
        </router-link>
      </div>
      <div class="tw-mt-2 md:tw-mt-0">
        <p class="tw-text-sm tw-text-gray-light2 tw-mb-2">Date of Creation</p>
        <p
          class="tw-font-CircularStd-medium tw-text-medium tw-text-secondary tw-text-base"
        >
          {{ dateOfCreation }}
        </p>
        <button
          v-if="spv.status === 'Pending'"
          @click="showSpvConfirmModal = true"
          class="tw-font-CircularStd-medium tw-text-medium tw-text-base tw-underline tw-text-[#C63434] tw-mt-8 hover:tw-bg-white hover:tw-text-[#C63434]"
        >
          Cancel Request
        </button>
        <button
          @click="showSpvConfirmModal = true"
          v-else
          class="tw-font-CircularStd-medium tw-text-medium tw-text-base tw-underline tw-text-[#C63434] tw-mt-8 hover:tw-bg-white hover:tw-text-[#C63434]"
        >
          Delete
        </button>
      </div>
    </div>
    <SpvInfo
      v-if="showSpvInfoModal"
      :spv="spv"
      @close-spv-info="showSpvInfoModal = false"
    />
    <ConfirmCancelModal
      :type="cancelType"
      v-if="showSpvConfirmModal"
      @confirm-cancel="getDeleteType"
      @close-cancel-modal="showSpvConfirmModal = false"
    />
    <SpvSuccessModal
      v-if="showSuccessModal"
      :type="cancelType"
      @close-success-modal="showSuccessModal = false"
    />
  </div>
</template>

<script setup>
  import { useStore } from "vuex";
  import { computed, ref } from "vue";
  import SpvInfo from "./SpvInfo.vue";
  import ConfirmCancelModal from "./ConfirmCancelModal.vue";
  import SpvSuccessModal from "./SpvSuccessModal.vue";

  const store = useStore();

  const { spv } = defineProps({
    spv: {
      type: Object,
      required: true,
    },
  });
  const emits = defineEmits(["refresh-list"]);

  const showSpvInfoModal = ref(false);
  const showSpvConfirmModal = ref(false);
  const showSuccessModal = ref(false);

  const spvType = computed(() =>
    spv.meta ? "Uploaded SPV Doc" : getSpvStatusText()
  );
  const cancelType = computed(() =>
    spv.status === "Pending" ? "Request" : "Document"
  );
  const spvTypeClass = computed(() =>
    spvType.value === "Uploaded SPV Doc"
      ? "tw-text-primary tw-bg-[#E6EDFF]"
      : getClassByStatus()
  );

  const spvCountry = computed(() => {
    let country = null;
    if (spv.meta?.country_of_incorporation) {
      country = spv.meta.country_of_incorporation?.split(" ")[0];
    } else {
      country = spv.country;
    }
    return country;
  });
  const dateOfCreation = computed(() => {
    let date = null;
    if (spv.meta?.date_of_creation) {
      date = spv.meta.date_of_creation.split("T")[0];
    } else {
      date = spv.createdAt.split("T")[0];
    }
    return date;
  });
  const orgName = computed(
    () => store.state.organisationModule.organisationDetails.name
  );

  const getSpvStatusText = () => {
    const status = spv.status;
    let statusText = null;
    switch (status) {
      case "Pending":
        statusText = "Pending Approval";
        break;
      case "Approved":
        statusText = "Pending Approval";
        break;
    }
    return statusText;
  };

  const getDeleteType = () => {
    if (spv.status === "Pending") {
      cancelSpvRequest(spv._id);
    } else {
      deleteSpv(spv._id);
    }
  };

  const cancelSpvRequest = async () => {
    const id = spv._id;
    const response = await store.dispatch("Spv/cancelSpvRequest", id);
    const { message } = response.data;
    store.dispatch("showToast", {
      description: message,
      display: true,
      type: "success",
    });
    emits("refresh-list");
  };

  const deleteSpv = async (id) => {
    const response = await store.dispatch("Spv/deleteSpv", id);
    const { message } = response.data;
    store.dispatch("showToast", {
      description: message,
      display: true,
      type: "success",
    });
    emits("refresh-list");
  };

  const getClassByStatus = () => {
    const status = spv.status;
    let statusClass = null;
    switch (status) {
      case "Pending":
        statusClass = "tw-bg-[#FCF9ED] tw-text-[#C06C00]";
        break;
      case "Rejected":
        statusClass = "tw-bg-[#FCE9E9] tw-text-[#C63434]";
        break;

      default:
        break;
    }
    return statusClass;
  };
</script>

<style lang="scss" scoped></style>
