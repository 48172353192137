<template>
  <div>
    <div
      class="tw-flex tw-rounded tw-border tw-border-[#DDE1E9] tw-h-[48px] tw-py-2 tw-px-[18px] tw-w-full custom-form__input"
      :class="[v$.$error ? 'tw-mb-1 custom-form__input--error' : null]"
    >
      <select
        name="code"
        id="code"
        class="tw-w-24 tw-border-0 tw-border-r tw-border-[#E1E6F0] tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-text-[#323E67] tw-max-w-[80px]"
        v-model="phone.code"
      >
        <option
          :value="code.dial_code"
          v-for="code in countryCode.countries"
          :key="code.code"
          class="tw-flex"
        >
          <p>
            {{ code.flag }}
            {{ code.dial_code }}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {{ code.name }}
          </p>
        </option>
      </select>
      <input
        type="tel"
        class="tw-border-0 tw-pl-3 tw-box-border tw-text-sm placeholder:tw-text-[#A3A8B3]"
        name="phone"
        id="phone"
        v-model="phone.number"
        :placeholder="required ? `${label} (required)` : 'label'"
      />
    </div>
    <p class="tw-text-red-error tw-font-normal" v-if="v$.$error">
      {{ v$.$errors[0].$message }}
    </p>
  </div>
</template>

<script setup>
  import { reactive, computed, watch, ref } from "vue";
  import countryCode from "@/utils/json/countryCode.json";
  import { useVuelidate } from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  const emit = defineEmits(["phone-input", "is-valid"]);
  const countrySplitter = require("country-code-splitter");

  const props = defineProps({
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: () => {
        return "Phone Number";
      },
    },
    currentValue: {
      type: [Object, String],
      required: false,
      default: () => {
        "";
      },
    },
    validate: {
      type: Boolean,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
    },
  });
  const isValid = ref(true);
  const name = computed(() => props.name);
  const currentValue = computed(() => props.currentValue);
  const validate = computed(() => props.validate);
  const phone = reactive({
    code: "+234",
    number: null,
  });
  const rules = {
    number: { required },
  };
  const v$ = useVuelidate(rules, phone);
  countryCode;
  const validateInput = () => {
    v$.value.$touch();
    isValid.value = !v$.value.$error;
    emit("is-valid", isValid.value);
  };
  const getNumber = () => {
    const validNumber = currentValue?.value.includes("+")
      ? currentValue.value
      : `+${currentValue.value}`;
    const { code, number } = countrySplitter.getNumber(validNumber);
    phone.code = code;
    phone.number = number;
  };

  watch(
    phone,
    (value) => {
      emit("phone-input", { name: name.value, value });
    },
    { deep: true }
  );
  watch(
    () => props.validate,
    (validate) => {
      if (validate) {
        setTimeout(() => {
          validateInput();
        }, 500);
      }
    }
  );
  watch(
    () => props.currentValue,
    () => {
      getNumber();
    }
  );
  if (currentValue.value && currentValue.value !== phone.number) {
    getNumber();
  }
</script>

<style lang="scss" scoped>
  .custom-form {
    &__input {
      transition: all 500ms ease-in-out;
      background-color: #fff;
      &--error {
        border: 1px solid #db4343;
      }
      &:focus {
        border: 1px solid #4d84ff;
      }
    }
  }
</style>
