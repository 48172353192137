<template>
  <SmallModal @close="$emit('close-modal')">
    <template v-slot:content>
      <img
        src="https://res.cloudinary.com/djalafcj9/image/upload/v1677228493/getequityV2/Alert_pvhpbm.png"
        class="tw-h-20 tw-w-20 tw-block tw-mx-auto tw-mb-4"
      />
      <p
        class="tw-text-secondary tw-text-center tw-font-bold tw-max-w-[261px] tw-mx-auto tw-mb-8"
      >
        {{ deal.name }} deal will be linked to this SPV
      </p>
      <p class="tw-text-[#323E67] tw-text-center">Do you want to proceed?</p>
      <div class="tw-mt-14 tw-grid tw-grid-cols-2 tw-gap-x-4">
        <button
          class="tw-rounded tw-text-secondary tw-bg-blue-bg200 tw-text-base"
          @click="$emit('close-modal')"
        >
          No
        </button>
        <BtnComponent
          title="Yes"
          :loading="loading"
          @click="$emit('link-deal')"
        />
      </div>
    </template>
  </SmallModal>
</template>

<script setup>
  import SmallModal from "../general/SmallModal.vue";
  import BtnComponent from "../general/BtnComponent.vue";
  import { useStore } from "vuex";
  import { computed } from "vue";
  const emit = defineEmits(["close-modal", "link-deal"]);
  const { deal } = defineProps({
    deal: {
      type: Object,
      required: true,
    },
  });

  const store = useStore();

  const loading = computed(() => store.getters["smallLoading"]);
</script>

<style lang="scss" scoped></style>
